import transactionApis from 'shared_js/api/public/transactions';
import draftsApis from 'shared_js/api/public/drafts';
import invoiceApis from 'shared_js/api/public/invoices';

export default {
  async fetchInvoices({ commit, state, getters }, parameters = {}) {
    const { isPagination = false } = parameters;
    const perPageInvoicesFetched = isPagination && getters.perPageTransactions;

    if (perPageInvoicesFetched || getters.fetchTransactionsStatus === null || state.invoices.length === getters.total) {
      return;
    }

    commit('SET_FETCH_TRANSACTIONS_STATUS', null);

    const { limit } = state;
    let offset = 0;
    if (isPagination) {
      offset = (getters.currentPage - 1) * limit;
    } else {
      offset = state.invoices.length;
    }
    const params = {
      states: ['pending'],
      types: ['buka-pengiriman'],
      offset,
      limit,
    };

    try {
      const response = await invoiceApis.getInvoices(params);
      const { meta } = response;
      let invoiceStatus = false;

      if (meta.http_status === 200) {
        invoiceStatus = true;
        const { data } = response;

        if (data && data.length > 0) {
          commit('SET_INVOICES', data);
          if (isPagination) {
            commit('SET_PER_PAGE_TRANSACTIONS', { page: getters.currentPage, transactions: data });
          }
        }

        commit('SET_TOTAL_PAGE', meta.total);
      }
      commit('SET_FETCH_TRANSACTIONS_STATUS', invoiceStatus);
    } catch (e) {
      commit('SET_FETCH_TRANSACTIONS_STATUS', false);
    }
  },

  async fetchTransactions({ commit, getters }, parameters = {}) {
    const { isPagination = false } = parameters;
    const perPageTransactionsFetched = isPagination && getters.perPageTransactions;

    if (
      perPageTransactionsFetched ||
      getters.fetchTransactionsStatus === null ||
      getters.transactions.length === getters.total
    ) {
      return;
    }

    commit('SET_FETCH_TRANSACTIONS_STATUS', null);

    try {
      const { paginationLimit } = getters;
      let offset = 0;
      if (isPagination) {
        offset = (getters.currentPage - 1) * paginationLimit;
      } else {
        offset = getters.transactions.length;
      }
      const active = getters.isTrxActive;
      delete parameters.isPagination;
      const params = {
        ...parameters,
        offset,
        limit: paginationLimit,
        ...(!parameters.fetchAll && { active }),
      };
      const response = await transactionApis.getTransactions(params);
      const { meta } = response;
      let status = false;

      if (meta.http_status === 200) {
        status = true;
        const { data } = response;

        if (data && data.length > 0) {
          const transactions = {};
          const transactionIds = data.map(trx => {
            transactions[trx.id] = trx;

            return trx.id;
          });
          commit('SET_DETAIL_TRANSACTIONS', transactions);
          commit('APPEND_TRANSACTIONS', transactionIds);
          if (isPagination) {
            commit('SET_PER_PAGE_TRANSACTIONS', { page: getters.currentPage, transactions: transactionIds });
          }
        }

        if (!parameters.fetchAll) {
          commit('SET_TOTAL_PAGE', meta.total);
        }
      }
      commit('SET_FETCH_TRANSACTIONS_STATUS', parameters.fetchAll ? '' : status);
    } catch (e) {
      commit('SET_FETCH_TRANSACTIONS_STATUS', false);
    }
  },
  async fetchTransaction({ commit, state }, id) {
    if (!id || state.detailTransactions[id]) {
      return;
    }

    try {
      const response = await transactionApis.getTransaction(id);
      const { meta } = response;

      if (meta.http_status === 200) {
        const { data } = response;
        commit('SET_DETAIL_TRANSACTIONS', { [id]: data });
      }
    } catch (err) {
      throw err;
    }
  },
  async fetchTransactionSummaries({ commit, state }, params) {
    if (state.fetchTransactionSummaryStatus === null) {
      return;
    }

    const summaryFromHash = state.transactionSummaryHash[params.period];
    if (summaryFromHash) {
      commit('SET_TRANSACTION_SUMMARY', summaryFromHash);
      commit('SET_FETCH_TRANSACTION_SUMMARY_STATUS', true);
      return;
    }

    commit('SET_FETCH_TRANSACTION_SUMMARY_STATUS', null);

    try {
      const response = await transactionApis.getTransactionSummaries(params);
      const { meta } = response;
      let status = false;

      if (meta.http_status === 200) {
        status = true;
        const { data } = response;
        if (data) {
          commit('SET_TRANSACTION_SUMMARY', data);
          if (data.period) {
            commit('SET_TRANSACTION_SUMMARY_HASH', data);
          }
        }
      }
      commit('SET_FETCH_TRANSACTION_SUMMARY_STATUS', status);
    } catch (error) {
      commit('SET_FETCH_TRANSACTION_SUMMARY_STATUS', false);
      commit('SET_FETCH_TRANSACTION_SUMMARY_ERROR', error);
      commit('SET_TRANSACTION_SUMMARY', null);
      throw error;
    }
  },
  async fetchTransactionRecaps({ commit, state }) {
    if ([null, true].includes(state.fetchTransactionRecapsStatus)) {
      return;
    }

    commit('SET_FETCH_TRANSACTION_RECAPS_STATUS', null);

    try {
      const response = await transactionApis.getTransactionRecaps();
      const { meta } = response;
      let status = false;

      if (meta.http_status === 200) {
        status = true;
        const { data } = response;
        if (data && data.length) {
          commit('SET_TRANSACTION_RECAPS', data);
        }
      }
      commit('SET_FETCH_TRANSACTION_RECAPS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_TRANSACTION_RECAPS_STATUS', false);
    }
  },
  async fetchDrafts({ commit, state, getters }, parameters = {}) {
    const { isPagination = false } = parameters;
    const perPageDraftsFetched = isPagination && getters.perPageTransactions;
    if (perPageDraftsFetched || getters.fetchTransactionsStatus === null || state.drafts.length === getters.total) {
      return;
    }

    commit('SET_FETCH_TRANSACTIONS_STATUS', null);

    const { searchKeyword: query } = getters;
    const { limit } = state;
    let offset = 0;
    if (isPagination) {
      offset = (getters.currentPage - 1) * limit;
    } else {
      offset = state.drafts.length;
    }
    const params = { offset, limit, query };

    try {
      const response = await draftsApis.getDrafts(params);
      const { meta } = response;
      let status = false;

      if (meta.http_status === 200) {
        status = true;
        const { data } = response;

        if (data && data.length > 0) {
          commit('SET_DRAFTS', data);
          if (isPagination) {
            commit('SET_PER_PAGE_TRANSACTIONS', { page: getters.currentPage, transactions: data });
          }
        }

        commit('SET_TOTAL_PAGE', meta.total);
      }
      commit('SET_FETCH_TRANSACTIONS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_TRANSACTIONS_STATUS', false);
    }
  },
  async fetchBukaPengirimanInvoices({ commit, state }, parameters = {}) {
    const { offset, isPagination = false } = parameters;

    if (state.bukaPengirimanInvoicesStatus.loading) {
      return;
    }

    commit('SET_BUKA_PENGIRIMAN_INVOICES_STATUS', {
      loading: true,
      error: false,
    });

    const { offset: paginationOffset, limit } = state.bukaPengirimanInvoicesPagination;
    const requestOffset = isPagination ? offset || 0 : state.bukaPengirimanInvoices.length;
    const params = {
      offset: requestOffset,
      limit,
      state: 'pending,created',
    };

    try {
      const response = await invoiceApis.getBukaPengirimanInvoices(params);
      const { data } = response;

      if (isPagination && data.length === 0) {
        commit('SET_BUKA_PENGIRIMAN_INVOICES_PAGINATION', {
          totalPages: Math.floor(paginationOffset / limit) + 1,
        });
      } else {
        commit('SET_BUKA_PENGIRIMAN_INVOICES', { isAppend: !isPagination, data });
        commit('SET_BUKA_PENGIRIMAN_INVOICES_PAGINATION', {
          offset,
          limit,
        });
      }

      commit('SET_BUKA_PENGIRIMAN_INVOICES_STATUS', {
        loading: false,
        error: false,
      });

      const transactionIds = state.bukaPengirimanInvoices
        .map(v => (v.transactions[0] ? v.transactions[0].id : undefined))
        .filter(v => !!v);
      const willBeRequesIds = transactionIds.filter(id => !state.detailTransactions[id]);
      if (willBeRequesIds.length) {
        const res = await transactionApis.getBulkTransactions(willBeRequesIds);
        commit('SET_DETAIL_TRANSACTIONS', res.data);
      }
    } catch (e) {
      commit('SET_BUKA_PENGIRIMAN_INVOICES_STATUS', {
        loading: false,
        error: true,
      });
    }
  },
};
