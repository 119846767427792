<template>
  <div class="c-empty-state u-txt--center">
    <img v-if="image" :src="image" class="u-mrgn-bottom--5">
    <bl-text-av variation="subheading-18" weight="semi-bold">{{ title }}</bl-text-av>
    <bl-text-av v-if="description" variation="body-14" class="u-mrgn-top--2">{{ description }}</bl-text-av>
    <bl-button-av v-if="action" :fullwidth="actionFullWidth" variation="primary" size="small" class="u-mrgn-top--3" @click="$emit('click-action')">
      {{ action }}
    </bl-button-av>
  </div>
</template>
<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';

export default {
  name: 'EmptyState',
  components: {
    BlTextAv,
    BlButtonAv,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    actionFullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./EmptyState.scss" lang="scss" scoped>
