/* eslint-disable no-throw-literal */
import transactionApis from 'shared_js/api/public/transactions';
import invoiceApis from 'shared_js/api/public/invoices';
import experimentApis from 'shared_js/api/exclusive/experiments';
import draftsApis from 'shared_js/api/public/drafts';
import cloneDeep from 'lodash.clonedeep';
import sendTrackRegisterInvoiceData from 'shared_js/business/Trackers/trackInvoiceRegister';
import curry from 'shared_js/helpers/curry-helper';
import { getUserId, generateSessionId } from 'shared_js/helpers/user-helper';
import { getErrorMessage } from 'shared_js/helpers/error-helper';
import { isTrxUseCOD, getCodAdminFee } from 'shared_js/helpers/cod-helper';
import { isUuidV4 } from 'shared_js/helpers/uuid-helper';
import { checkValidateVoucher } from 'shared_js/helpers/voucher-helper';
import { getCheckoutUrl } from 'shared_js/helpers/url-helper';
import { IS_SSR } from 'shared_js/constant/general';
import { DEFAULT_TRANSACTION } from '../constants';

export default {
  async saveTransaction({ commit, state, dispatch, rootState, rootGetters }) {
    if (state.saveTransactionStatus === null) {
      return;
    }

    commit('SET_SAVE_TRANSACTION_STATUS', null);

    const { toggle, courier: courierState } = rootState;
    const isToggleCODDropoffEnabled = Boolean(
      rootGetters['configuration/isRolePartner']
        ? toggle.togglePartnerCodDropoffEnabled
        : toggle.toggleCodDropoffEnabled,
    );
    if (toggle && isToggleCODDropoffEnabled && courierState && courierState.codCouriersCount) {
      commit('SET_TRX_COD_COURIERS_COUNT', courierState.codCouriersCount);
    }

    try {
      const { saveContact, to: address } = state.transaction;
      if (!address.id && saveContact) {
        const payload = {
          ...(address.title && { title: address.title }),
          ...(address.email && { email: address.email }),
          ...(address.area && { area: address.area }),
          ...(address.city && { city: address.city }),
          ...(address.province && { province: address.province }),
          ...(address.latitude && { latitude: address.latitude }),
          ...(address.longitude && { longitude: address.longitude }),
          geolocation_set_by_user: address.geolocation_set_by_user,
          name: address.name,
          phone: address.phone,
          address: address.address,
          post_code: address.post_code,
        };

        const response = await dispatch('recipient/createRecipientAddress', payload, { root: true });

        commit('SET_RECIPIENT_DETAIL', response.data);
      }

      commit('SET_REBOOK_AUTO_SUBMIT_DONE');
      commit('SAVE_TRANSACTION');

      commit('SET_SAVE_TRANSACTION_STATUS', true);
    } catch (e) {
      commit('SET_SAVE_TRANSACTION_STATUS', false);
    }
  },
  async sendCleanupCTAExperimentMetrics({ state, rootGetters }) {
    const experimentStatus = rootGetters['experiment/bukasendCleanupCTAExperimentStatus'];

    if (!experimentStatus) {
      return;
    }

    const participant = getUserId() || '0';
    const experimentName = rootGetters['experiment/bukasendCleanupCTAExperimentKey'];
    const totalTrx = state.transactions.length;

    await experimentApis.scoreMetric({
      experimentName,
      participant,
      metric: 'total_trx',
      value: totalTrx,
    });
  },
  async sendHomeVoucherBannerMetrics({ rootGetters }) {
    const experimentStatus = rootGetters['experiment/homeVoucherBannerExperimentStatus'];

    if (!experimentStatus) {
      return;
    }

    try {
      await experimentApis.scoreMetric({
        experimentName: rootGetters['experiment/homeVoucherBannerExperimentKey'],
        participant: getUserId(),
        metric: 'click_lanjutkan_pembayaran',
        value: 1,
      });
    } catch (e) {
      // do nothing
    }
  },
  async createInvoice(
    { rootState, dispatch },
    {
      transactions,
      sendCleanupCTAMetric,
      sendHomeVoucherBannerMetric,
      sendCostCheckParentPageExperimentMetric,
      trackClickContinuePay,
      paymentReferenceType,
    },
  ) {
    const { toggle } = rootState;
    const methodName = toggle.toggleBukapengirimanInvoiceEnabled ? 'createBukaPengirimanInvoice' : 'createInvoice';
    const parameters = { transactions };
    if (toggle.toggleBukapengirimanInvoiceEnabled && paymentReferenceType) {
      parameters.payment_reference_type = paymentReferenceType;
    }
    const { data, meta } = await invoiceApis[methodName](parameters);
    if (meta.http_status === 201) {
      if (trackClickContinuePay) {
        trackClickContinuePay({ invoiceID: data.id, actionStatus: 'success' });
      }
      await sendTrackRegisterInvoiceData(data);
      if (sendCleanupCTAMetric) {
        await dispatch('sendCleanupCTAExperimentMetrics');
      }
      if (sendHomeVoucherBannerMetric) {
        await dispatch('sendHomeVoucherBannerMetrics');
      }
      if (sendCostCheckParentPageExperimentMetric) {
        await sendCostCheckParentPageExperimentMetric('click_continue_to_pay');
      }

      if (!IS_SSR) {
        if (toggle.toggleBukapengirimanInvoiceEnabled) {
          window.location.href = data.checkout_url;
        } else {
          window.location.href = getCheckoutUrl(data.payment_id);
        }
      }
    } else if (trackClickContinuePay) {
      trackClickContinuePay({ actionStatus: 'failed to create invoice' });
    }
  },
  async createBulkTransactions({ commit, state, rootState, rootGetters, dispatch }, parameters = {}) {
    if (state.createBulkTransactionStatus === null) {
      return;
    }

    commit('SET_CREATE_BULK_TRANSACTION_STATUS', null);
    const { trackClickContinuePay } = parameters;

    try {
      const {
        params,
        trackers,
        sendCleanupCTAMetric,
        sendHomeVoucherBannerMetric,
        sendCostCheckParentPageExperimentMetric,
        skipCheckout,
        transactionsWithCashback,
        paymentReferenceType,
      } = parameters;

      const hasCreatedTrx = state.transactions.some(trx => !!trx.id);

      if (!hasCreatedTrx || !state.groupId) {
        commit('SET_GROUP_ID', generateSessionId());
      }

      const groupId = state.transactions.length > 1 ? state.groupId : '';

      const promotionToken = rootState.toggle.togglePromotionTokenEnabled
        ? window.btoa(
            JSON.stringify({
              group_id: groupId,
              data: state.transactions.map((trx, idx) => ({
                promotion_id: transactionsWithCashback[idx].promotionId,
                promo_type: transactionsWithCashback[idx].promoType,
                service: trx.courier.service,
                service_name: trx.courier.service_name,
                cod: Boolean(isTrxUseCOD(trx) && trx.codChecked),
                price: trx.courier.price,
              })),
            }),
          )
        : null;

      const limitCODToken =
        rootState.toggle.toggleLimitCODTransactionEnabled && !rootGetters['configuration/isRolePartner']
          ? window.btoa(
              JSON.stringify({
                group_id: groupId,
                total: state.transactions.filter(trx => trx.codChecked).length,
              }),
            )
          : null;

      const transactions = state.transactions.map((transaction, trxIndex) => {
        const isUseCod = isTrxUseCOD(transaction);
        const codAdminFee = getCodAdminFee(transaction);
        const originWeight = transaction.weightInput || (transaction.volumetric ? 0 : transaction.weight);
        const customFields = {};

        if (transaction.custom_fields) {
          customFields.custom_fields = transaction.custom_fields;
        }
        if (state.formSelectedVoucher) {
          customFields.custom_fields = {
            ...customFields.custom_fields,
            voucher_code: state.formSelectedVoucher.voucher_code,
          };
        }

        let { uniqueId = '' } = transaction;

        if (rootState.toggle.toggleIdempotencyEnabled && !uniqueId) {
          uniqueId = generateSessionId();
          commit('SET_TRANSACTIONS_UNIQUE_ID_BY_INDEX', {
            index: trxIndex,
            uniqueId,
          });
        }

        const additionalParams = {};
        if (limitCODToken) {
          additionalParams.limit_cod = limitCODToken;
        }
        if (rootState.toggle.toggleLimitBulkUploadUserEnabled && transaction.bulkUploadEligibilityHash) {
          additionalParams.bulk_upload = transaction.bulkUploadEligibilityHash;
        }

        return {
          ...params,
          order_name: transaction.notes,
          order_total: transaction.order_total,
          order_price: transaction.order_price,
          insured: transaction.insured,
          delivery_insurance_type: transaction.selectedInsuranceProduct.length
            ? transaction.selectedInsuranceProduct[0].type
            : null,
          weight: transaction.weight,
          origin_weight: originWeight,
          notes: transaction.notes,
          courier: transaction.courier.service_name,
          pickup_request_time: transaction.pickup_request_time,
          courier_service_type: transaction.courier.courier_service_type,
          original_sender:
            (transaction.dropship && {
              name: transaction.from.name,
              phone: transaction.from.phone,
            }) ||
            null,
          from: {
            ...transaction.from,
            ...(transaction.dropship && {
              name: transaction.senderAlias.name,
              phone: transaction.senderAlias.phone,
            }),
          },
          to: { ...transaction.to },
          amount: {
            total: transaction.amount.total + codAdminFee,
            details: {
              shipping: transaction.amount.shipping,
              discrepancy: transaction.amount.discrepancy,
              insurance: transaction.amount.insurance,
              ...(isUseCod && {
                cod_admin_fee: codAdminFee,
              }),
            },
          },
          ...(transaction.volumetric && {
            height: transaction.height,
            width: transaction.width,
            length: transaction.length,
          }),
          ...customFields,
          ...(isUseCod && {
            cod: transaction.codChecked,
            cod_value: parseInt(transaction.codAmount || 0, 10),
          }),
          ...(transaction.draftData && transaction.draftData.id && { draft_id: transaction.draftData.id }),
          id: transaction.id,
          promotion_token: promotionToken,
          unique_id: uniqueId,
          ...(Object.keys(additionalParams).length && { additional_params: additionalParams }),
        };
      });

      const { shipping, insurance, cod_admin_fee: codAdminFee } = transactions[0].amount.details;
      const discrepancy = rootState.configuration.configuration.discrepancy_amount;

      transactions[0].amount = {
        total: shipping + insurance + discrepancy + (codAdminFee || 0),
        details: {
          shipping,
          insurance,
          discrepancy,
          ...(codAdminFee >= 0 && {
            cod_admin_fee: parseInt(codAdminFee, 10),
          }),
        },
      };

      const invoiceParams = transactions.filter(trx => !!trx.id).map(({ id }) => ({ id, type: 'buka-pengiriman' }));

      await transactions.reduce(async (res, transaction, index) => {
        await res;

        if (!transaction.id) {
          const response = await transactionApis.createTransaction(transaction);
          const { id, type } = response.data;
          invoiceParams.push({ id, type });
          commit('SET_TRANSACTIONS_ID_BY_INDEX', { index, id });

          return response;
        }

        return Promise.resolve(true);
      }, []);

      // eslint-disable-next-line no-underscore-dangle
      const tracker = (this._vm || {}).$tracker;
      const { extraTrackerParams, trackerParams, isBuyer } = trackers;
      if (tracker) {
        if (trackerParams) {
          if (isBuyer) {
            tracker.buyerReturnWithBukasendProceedToPayment({
              ...trackerParams,
              bukasendTransactionId: invoiceParams[0].id,
            });
          } else {
            tracker.sellerReturnWithBukasendProceedToPayment({
              ...trackerParams,
              bukasendTransactionId: invoiceParams[0].id,
              sessionId: rootState.configuration.sessionId,
            });
          }
        }

        if (extraTrackerParams && Object.keys(extraTrackerParams).length > 0) {
          const trackerKey = extraTrackerParams.tracker_key;
          delete extraTrackerParams.tracker_key;
          tracker.trackEvent(trackerKey, {
            ...extraTrackerParams,
            bukasend_transaction_id: invoiceParams[0].id,
          });
        }
      }

      if (skipCheckout) {
        commit('SET_CREATE_BULK_TRANSACTION_STATUS', true);
        return;
      }

      await dispatch('createInvoice', {
        sendCleanupCTAMetric,
        sendHomeVoucherBannerMetric,
        sendCostCheckParentPageExperimentMetric,
        trackClickContinuePay,
        transactions: invoiceParams,
        paymentReferenceType,
      });
      commit('SET_CREATE_BULK_TRANSACTION_STATUS', true);
    } catch (e) {
      const isIdempotencyError = Boolean(
        (curry(['response', 'data', 'errors'], e) || []).some(err => err.code === 11118),
      );
      if (
        isIdempotencyError &&
        rootState.toggle.toggleIdempotencyEnabled &&
        rootState.toggle.toggleIdempotencyRegenerateUniqueIdEnabled
      ) {
        commit('REGENERATE_TRANSACTIONS_UNIQUE_ID');
      }
      commit('SET_CREATE_BULK_TRANSACTION_STATUS', false);
      if (trackClickContinuePay) {
        trackClickContinuePay({ actionStatus: getErrorMessage(e) });
      }
      throw e;
    }
  },
  async setTransactions({ commit, rootState }, params) {
    const { packages, senderAddress } = params;

    const { id, name, title, phone, address } = senderAddress;
    const senderAddressParams = {
      id,
      name,
      title,
      phone,
      email: '',
      address: address.address,
      area: address.district,
      city: address.city,
      province: address.province,
      post_code: address.postal_code,
      latitude: address.latitude,
      longitude: address.longitude,
    };

    const transactions = packages.map(data => {
      const transaction = cloneDeep(DEFAULT_TRANSACTION);

      transaction.weight = data.weight * 1000;
      transaction.weightInput = transaction.weight;
      transaction.notes = data.notes;

      const contentCategories = rootState.configuration.packageContentCategories || [];
      const contentCategory =
        contentCategories.find(category => category.toLowerCase() === data.notes.toLowerCase()) || 'OTHER';
      transaction.contentCategory = contentCategory;

      if (data.id) {
        transaction.id = data.id;
      }
      if (data.dropship) {
        transaction.dropship = data.dropship;
      }
      if (data.senderAlias) {
        transaction.senderAlias = data.senderAlias;
      }

      if (data.width && data.height && data.length) {
        transaction.volumetric = true;
        transaction.width = data.width;
        transaction.height = data.height;
        transaction.length = data.length;
      }

      transaction.from = senderAddressParams;

      transaction.to = {
        id: data.recipient.id,
        name: data.recipient.name,
        title: '',
        phone: data.recipient.phone,
        email: '',
        address: data.recipient.address,
        area: data.recipient.area,
        city: data.recipient.city,
        province: data.recipient.province,
        post_code: data.recipient.post_code,
        latitude: data.recipient.latitude,
        longitude: data.recipient.longitude,
      };

      transaction.courier = {
        ...data.service,
        courier_service_type: data.deliveryType,
      };

      transaction.amount = {
        total: data.service.price,
        shipping: data.service.price,
        insurance: 0,
        discrepancy: 0,
      };

      if (data.insuranceAutoOptedIn) {
        transaction.insuranceAutoOptedIn = data.insuranceAutoOptedIn;
      }

      if (data.selectedInsuranceProduct) {
        transaction.selectedInsuranceProduct = data.selectedInsuranceProduct;
      }

      if (data.insuranceProductPrice) {
        transaction.order_price = data.insuranceProductPrice;
      }

      if (data.customField) {
        transaction.custom_fields = { order_id: data.customField };
      }

      transaction.hasClickCourierSection = data.hasClickCourierSection || false;

      if (rootState.toggle.toggleLimitBulkUploadUserEnabled) {
        transaction.bulkUploadEligibilityHash = data.bulkUploadEligibilityHash;
      }

      return transaction;
    });

    commit('SET_TRANSACTIONS', transactions);
  },
  async setSelectedInsuranceProduct({ commit }, payload) {
    commit('SET_SELECTED_INSURANCE_PRODUCT', payload);
  },
  async saveDraft({ commit, state, getters }, successCallback) {
    if (state.saveDraftStatus === null || !getters.isDraft) {
      return;
    }

    const { transaction } = state;
    const { senderAlias } = transaction;
    const { isDraftAddress, isSenderAddress, ...draftFrom } = transaction.from;
    const { isDraftAddress: isDraftAddressTo, ...draftTo } = transaction.to;
    const { courier } = transaction;
    const draftData = transaction.draftData || {};
    const {
      id: originalFromId,
      isDraftAddress: isDraftAddressOriginalFrom,
      isSenderAddress: isSenderAddressOriginalFrom,
      ...draftOriginalFrom
    } = draftData.originalFrom || {};
    const { id: originalToId, isDraftAddress: isDraftAddressOriginalTo, ...draftOriginalTo } =
      draftData.originalTo || {};

    const params = {
      order_name: transaction.order_name,
      order_total: transaction.order_total,
      order_price: parseInt(transaction.order_price, 10) || 0,
      weight: transaction.weight,
      height: transaction.height,
      width: transaction.width,
      length: transaction.length,
      notes: transaction.notes,
      from: {
        ...draftFrom,
        ...(transaction.dropship && { name: senderAlias.name, phone: senderAlias.phone }),
        id: isUuidV4(draftFrom.id) ? null : parseInt(draftFrom.id, 10) || null,
      },
      to: {
        ...draftTo,
        id: isUuidV4(draftTo.id) ? null : parseInt(draftTo.id, 10) || null,
      },
      original_sender: {
        name: transaction.dropship ? draftFrom.name : '',
        phone: transaction.dropship ? draftFrom.phone : '',
      },
      courier: courier.service_name || null,
      courier_service_type: courier.courier_service_type || null,
      pickup_request_time: transaction.pickup_request_time,
      cod: transaction.codChecked,
      cod_value: transaction.codAmount ? parseInt(transaction.codAmount, 10) : 0,
      additional_data: {
        original_from: {
          ...draftOriginalFrom,
          ...(transaction.dropship && senderAlias),
        },
        original_to: draftOriginalTo,
      },
    };

    commit('SET_SAVE_DRAFT_STATUS', null);
    try {
      const response = await draftsApis.updateDraft(draftData.id, params);
      const { meta } = response || {};
      const status = meta && meta.http_status === 200;
      commit('SET_SAVE_DRAFT_STATUS', status);
      successCallback();
    } catch (error) {
      commit('SET_SAVE_DRAFT_STATUS', false);
    }
  },
  async validateVoucher({ commit, state, getters }, voucher) {
    if (state.voucherValidateStatus === null) {
      return;
    }

    commit('SET_VOUCHER_VALIDATE_STATUS', null);

    const { isValid, voucherData, message } = await checkValidateVoucher({
      voucherCode: voucher.voucher_code,
      transactions: getters.voucherValidationTransactions,
    });
    commit('SET_FORM_SELECTED_VOUCHER', isValid ? voucherData : null);
    commit('SET_VOUCHER_VALIDATE_STATUS', isValid);
    if (!isValid) {
      throw {
        response: {
          data: {
            errors: [{ message }],
          },
        },
      };
    }
  },
  removeVoucherCode({ commit }) {
    commit('SET_VOUCHER_VALIDATE_STATUS', '');
    commit('SET_FORM_SELECTED_VOUCHER', null);
  },
};
