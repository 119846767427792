const { removeTrailingSlash } = require('../../../../shared/javascripts/helpers/url-helper');
const {
  BUKASEND_APP,
  PICKUP_FAILED,
  BUKASEND_DESKTOP,
  REFERRAL,
  HANDLING_BACK_DEVICE_ENABLED,
  MITRA_PRINT_RECEIPT,
  MITRA_BULK_PRINT_RECEIPT,
  BUKASEND_RETURN,
  DELIVERY_INSURANCE,
  COURIER_PICKUP_TIME_REQUEST,
  ON_BOARDING,
  MANUAL_BOOKING_ENABLED,
  DESKTOP_RANDOM_PINPOINT_ENABLED,
  SET_LOCATION_IN_COURIER_LIST_ENABLED,
  CASHBACK_3PL_ENABLED,
  ALLOW_USER_CANCEL_TRANSACTION,
  AUTO_TOPUP_CASHBACK,
  USP_BOARDING,
  NEW_RECIPIENT_NAME_VALIDATION_ENABLED,
  SENDER_GEOLOCATION_FLAG_ENABLED,
  DISCREPANCY_CHANGES_ALERT_ENABLED,
  CANCEL_TRANSACTION,
  DISCREPANCY_DETAIL_ENABLED,
  ONDEMAND_LIVE_TRACKING_URL_ENABLED,
  SAVE_HANDLING_ENABLED,
  TRANSACTION_RECAP_ENABLED,
  TRANSACTION_RECAP_IN_LANDING_ENABLED,
  TRANSACTION_RECAP_DOWNLOAD_ENABLED,
  BUKASEND_RETURN_PICKUP_ENABLED,
  PERMISSIONS_BY_ROLE_ENABLED,
  TESTIMONIES_ENABLED,
  ONDEMAND_COURIER_SERVICES_CONFIG_ENABLED,
  MEMBERSHIPS_ENABLED,
  VOUCHER_ENABLED,
  ONGKIR_GOKIL_ENABLED,
  TRX_DETAIL_VOUCHER_INFO_ENABLED,
  PROFILE_PAGE_ENABLED,
  PICKUP_GUARANTEE_NEW_USER,
  BUKASEND_COD_ENABLED,
  BUKASEND_COD_DROPOFF_ENABLED,
  BUKASEND_PARTNER_COD_DROPOFF_ENABLED,
  INSURANCE_PRODUCT_PRICE_ENABLED,
  DELIVERY_WITH_COOLBOX_ENABLED,
  PHONE_VERIFICATION_ENABLED,
  ADDRESS_REVAMP_ENABLED,
  KYC_ENABLED,
  PICKUP_GUARANTEE_ALL_USER_ENABLED,
  LANDING_VOUCHER_BANNER_ENABLED,
  HOME_VOUCHER_BANNER_ENABLED,
  ENCYCLOPEDIA_AGENT_ENABLED,
  SHOPIFY_EXTENSION_ENABLED,
  HOME_DWEB_ENABLED,
  PROFILE_DWEB_ENABLED,
  DELIVERY_COST_CHECK_DWEB_ENABLED,
  ADDRESS_BOOK_ENABLED,
  TRANSACTIONS_PAGE_DWEB_ENABLED,
  SEND_BULK_DRAFT_ENABLED,
  HOME_PAGE_DOPE_ENABLED,
  MADO_ENABLED,
  FORCE_KYC_VALIDATION_ENABLED,
  ALLOW_USER_FILL_BOTH_WEIGHT_ENABLED,
  PARTNER_HIDE_PROMO_ENABLED,
  PARTNER_SKIP_CHECKOUT_ENABLED,
  PARTNER_ROLE_ENABLED,
  DISCREPANCY_BLOCK_TRANSACTION_ENABLED,
  LANDING_COD_BANNER_ENABLED,
  REVAMP_LANDING_V2_ENABLED,
  PROMOTION_BUDGET_ENABLED,
  PROMOTION_TOKEN_ENABLED,
  MEMBERSHIP_RULE_ENABLED,
  COST_SPLIT_ENABLED,
  NON_MEMBERSHIP_ENABLED,
  IDEMPOTENCY_ENABLED,
  IDEMPOTENCY_REGENERATE_UNIQUE_ID_ENABLED,
  DIFF_SERVICE_TYPE_CASHBACK_ENABLED,
  LIMIT_BULK_UPLOAD_USER_ENABLED,
  LIMIT_COD_TRANSACTION_ENABLED,
  NEW_FLOW_KYC_ENABLED,
  MADO_BUKASEND_KYC_ENABLED,
  BLOCK_DUKCAPIL_SUBMITTED_ENABLED,
  MITRA_WALLET_ENABLED,
  MITRA_WALLET_BLOCK_TRANSACTION_ENABLED,
  MITRA_WALLET_ANOTHER_ENTRY_POINT_ENABLED,
  MITRA_WALLET_PAYMENT_METHOD_ENABLED,
  REGISTER_MITRA_AGENT_PHONE_VERIFIED_ENABLED,
  TRANSACTION_LIST_REVAMP_ENABLED,
  BANNER_SLIDER_ENDPOINT_ENABLED,
  MEMBERSHIP_REVAMP_ENABLED,
  CHECK_DUKCAPIL_ENABLED,
  ONGKIR_GOKIL_INTEGRATION_ENABLED,
  MULTIPLE_PROMOTION_INTEGRATION_ENABLED,
  MITRA_SUBDOMAIN_ENABLED,
  DISABLE_MULTIPLE_PACKAGE_ENABLED,
  BUKAPENGIRIMAN_INVOICE_ENABLED,
} = require('../../../../shared/javascripts/constant/toggles').NEO_TOGGLE_KEYS;
const { IS_SSR } = require('../../../../shared/javascripts/constant/general');

function buildToggleParams({
  state,
  isLoggedIn,
  additionalRequests,
  neoTogglePlatform = '',
  path = '',
  nodeCache,
  cachePrefix,
}) {
  let loggedRequests = {};
  const notLoggedRequests = {
    toggleApp: BUKASEND_APP,
    toggleTransactionReferrer: REFERRAL,
    toggleCashback3plEnabled: CASHBACK_3PL_ENABLED,
    togglePermissionsByRoleEnabled: PERMISSIONS_BY_ROLE_ENABLED,
    toggleTestimoniesEnabled: TESTIMONIES_ENABLED,
    toggleVoucherEnabled: VOUCHER_ENABLED,
    toggleOngkirGokilEnabled: ONGKIR_GOKIL_ENABLED,
    toggleBukasendDesktop: BUKASEND_DESKTOP,
    toggleHandlingBackDeviceEnabled: HANDLING_BACK_DEVICE_ENABLED,
    toggleCourierPickupTimeRequest: COURIER_PICKUP_TIME_REQUEST,
    toggleDesktopRandomPinpointEnabled: DESKTOP_RANDOM_PINPOINT_ENABLED,
    toggleSetLocationInCourierListEnabled: SET_LOCATION_IN_COURIER_LIST_ENABLED,
    toggleAutoTopupCashback: AUTO_TOPUP_CASHBACK,
    toggleNewRecipientNameValidationEnabled: NEW_RECIPIENT_NAME_VALIDATION_ENABLED,
    toggleSenderGeolocationFlagEnabled: SENDER_GEOLOCATION_FLAG_ENABLED,
    toggleDiscrepancyChangesAlertEnabled: DISCREPANCY_CHANGES_ALERT_ENABLED,
    toggleDiscrepancyDetailEnabled: DISCREPANCY_DETAIL_ENABLED,
    toggleProfilePageEnabled: PROFILE_PAGE_ENABLED,
    toggleSaveHandlingEnabled: SAVE_HANDLING_ENABLED,
    togglePickupGuaranteeNewUser: PICKUP_GUARANTEE_NEW_USER,
    toggleInsuranceProductPriceEnabled: INSURANCE_PRODUCT_PRICE_ENABLED,
    toggleDeliveryWithCoolbox: DELIVERY_WITH_COOLBOX_ENABLED,
    toggleAddressRevampEnabled: ADDRESS_REVAMP_ENABLED,
    togglePickupGuaranteeAllUserEnabled: PICKUP_GUARANTEE_ALL_USER_ENABLED,
    toggleLandingVoucherBannerEnabled: LANDING_VOUCHER_BANNER_ENABLED,
    toggleShopifyExtensionEnabled: SHOPIFY_EXTENSION_ENABLED,
    toggleAllowUserFillBothWeightEnabled: ALLOW_USER_FILL_BOTH_WEIGHT_ENABLED,
    toggleLandingCodBannerEnabled: LANDING_COD_BANNER_ENABLED,
    toggleRevampLandingV2Enabled: REVAMP_LANDING_V2_ENABLED,
    toggleDeliveryCostCheckDwebEnabled: DELIVERY_COST_CHECK_DWEB_ENABLED,
    togglePromotionBudgetEnabled: PROMOTION_BUDGET_ENABLED,
    togglePromotionTokenEnabled: PROMOTION_TOKEN_ENABLED,
    toggleDiffServiceTypeCashbackEnabled: DIFF_SERVICE_TYPE_CASHBACK_ENABLED,
    toggleNewFlowKycEnabled: NEW_FLOW_KYC_ENABLED,
    toggleLimitBulkUploadUserEnabled: LIMIT_BULK_UPLOAD_USER_ENABLED,
    toggleBlockDukcapilSubmittedEnabled: BLOCK_DUKCAPIL_SUBMITTED_ENABLED,
    toggleBannerSliderEndpointEnabled: BANNER_SLIDER_ENDPOINT_ENABLED,
    toggleCheckDukcapilEnabled: CHECK_DUKCAPIL_ENABLED,
    toggleOngkirGokilIntegrationEnabled: ONGKIR_GOKIL_INTEGRATION_ENABLED,
    toggleMultiplePromotionEnabled: MULTIPLE_PROMOTION_INTEGRATION_ENABLED,
    toggleMitraSubdomainEnabled: MITRA_SUBDOMAIN_ENABLED,
    toggleDisableMultiplePackageEnabled: DISABLE_MULTIPLE_PACKAGE_ENABLED,
  };

  if (isLoggedIn) {
    loggedRequests = {
      togglePickupFailed: PICKUP_FAILED,
      toggleMitraPrintReceipt: MITRA_PRINT_RECEIPT,
      toggleMitraBulkPrintReceipt: MITRA_BULK_PRINT_RECEIPT,
      toggleBukasendReturn: BUKASEND_RETURN,
      toggleDeliveryInsurance: DELIVERY_INSURANCE,
      toggleOnboarding: ON_BOARDING,
      toggleAllowUserCancelTransaction: ALLOW_USER_CANCEL_TRANSACTION,
      toggleUspBoarding: USP_BOARDING,
      toggleCancelTransaction: CANCEL_TRANSACTION,
      toggleOndemandLiveTrackingUrlEnabled: ONDEMAND_LIVE_TRACKING_URL_ENABLED,
      toggleTransactionRecapEnabled: TRANSACTION_RECAP_ENABLED,
      toggleTransactionRecapInLandingEnabled: TRANSACTION_RECAP_IN_LANDING_ENABLED,
      toggleTransactionRecapDownloadEnabled: TRANSACTION_RECAP_DOWNLOAD_ENABLED,
      toggleBukasendReturnPickupEnabled: BUKASEND_RETURN_PICKUP_ENABLED,
      toggleOndemandCourierServicesConfigEnabled: ONDEMAND_COURIER_SERVICES_CONFIG_ENABLED,
      toggleMembershipEnabled: MEMBERSHIPS_ENABLED,
      toggleTrxDetailVoucherInfoEnabled: TRX_DETAIL_VOUCHER_INFO_ENABLED,
      toggleManualBookingEnabled: MANUAL_BOOKING_ENABLED,
      toggleCodEnabled: BUKASEND_COD_ENABLED,
      toggleCodDropoffEnabled: BUKASEND_COD_DROPOFF_ENABLED,
      togglePartnerCodDropoffEnabled: BUKASEND_PARTNER_COD_DROPOFF_ENABLED,
      togglePhoneVerificationEnabled: PHONE_VERIFICATION_ENABLED,
      toggleKycEnabled: KYC_ENABLED,
      toggleHomeVoucherBannerEnabled: HOME_VOUCHER_BANNER_ENABLED,
      toggleEncyclopediaAgentEnabled: ENCYCLOPEDIA_AGENT_ENABLED,
      toggleHomeDwebEnabled: HOME_DWEB_ENABLED,
      toggleProfileDwebEnabled: PROFILE_DWEB_ENABLED,
      toggleAddressBookEnabled: ADDRESS_BOOK_ENABLED,
      toggleTransactionsPageDwebEnabled: TRANSACTIONS_PAGE_DWEB_ENABLED,
      toggleSendBulkDraftEnabled: SEND_BULK_DRAFT_ENABLED,
      toggleHomePageDopeEnabled: HOME_PAGE_DOPE_ENABLED,
      toggleMadoEnabled: MADO_ENABLED,
      toggleForceKycValidationEnabled: FORCE_KYC_VALIDATION_ENABLED,
      togglePartnerHidePromoEnabled: PARTNER_HIDE_PROMO_ENABLED,
      togglePartnerSkipCheckoutEnabled: PARTNER_SKIP_CHECKOUT_ENABLED,
      togglePartnerRoleEnabled: PARTNER_ROLE_ENABLED,
      toggleDiscrepanyBlockTransactionEnabled: DISCREPANCY_BLOCK_TRANSACTION_ENABLED,
      toggleLandingCodBannerEnabled: LANDING_COD_BANNER_ENABLED,
      toggleMembershipRuleEnabled: MEMBERSHIP_RULE_ENABLED,
      toggleCostSplitEnabled: COST_SPLIT_ENABLED,
      toggleNonMembershipEnabled: NON_MEMBERSHIP_ENABLED,
      toggleIdempotencyEnabled: IDEMPOTENCY_ENABLED,
      toggleIdempotencyRegenerateUniqueIdEnabled: IDEMPOTENCY_REGENERATE_UNIQUE_ID_ENABLED,
      toggleLimitCODTransactionEnabled: LIMIT_COD_TRANSACTION_ENABLED,
      toggleMadoBukasendKycEnabled: MADO_BUKASEND_KYC_ENABLED,
      toggleMitraWalletEnabled: MITRA_WALLET_ENABLED,
      toggleMitraWalletBlockTransactionEnabled: MITRA_WALLET_BLOCK_TRANSACTION_ENABLED,
      toggleMitraWalletAnotherEntryPoint: MITRA_WALLET_ANOTHER_ENTRY_POINT_ENABLED,
      toggleMitraWalletPaymentMethodEnabled: MITRA_WALLET_PAYMENT_METHOD_ENABLED,
      toggleRegisterMitraAgentPhoneVerifiedEnabled: REGISTER_MITRA_AGENT_PHONE_VERIFIED_ENABLED,
      toggleTransactionListRevampEnabled: TRANSACTION_LIST_REVAMP_ENABLED,
      toggleMembershipRevampEnabled: MEMBERSHIP_REVAMP_ENABLED,
      toggleBukapengirimanInvoiceEnabled: BUKAPENGIRIMAN_INVOICE_ENABLED,
    };
  }

  const requests = {
    ...notLoggedRequests,
    ...loggedRequests,
  };

  const requestsByPlatform = [
    SAVE_HANDLING_ENABLED,
    TRANSACTION_RECAP_ENABLED,
    TRANSACTION_RECAP_IN_LANDING_ENABLED,
    TRANSACTION_RECAP_DOWNLOAD_ENABLED,
    PERMISSIONS_BY_ROLE_ENABLED,
    TESTIMONIES_ENABLED,
    VOUCHER_ENABLED,
    PICKUP_GUARANTEE_NEW_USER,
    DELIVERY_INSURANCE,
    BUKASEND_COD_ENABLED,
    BUKASEND_COD_DROPOFF_ENABLED,
    BUKASEND_PARTNER_COD_DROPOFF_ENABLED,
    DELIVERY_WITH_COOLBOX_ENABLED,
    PHONE_VERIFICATION_ENABLED,
    ADDRESS_REVAMP_ENABLED,
    KYC_ENABLED,
    PICKUP_GUARANTEE_ALL_USER_ENABLED,
    LANDING_VOUCHER_BANNER_ENABLED,
    HOME_VOUCHER_BANNER_ENABLED,
    ENCYCLOPEDIA_AGENT_ENABLED,
    ADDRESS_BOOK_ENABLED,
    HOME_PAGE_DOPE_ENABLED,
    MADO_ENABLED,
    FORCE_KYC_VALIDATION_ENABLED,
    ALLOW_USER_FILL_BOTH_WEIGHT_ENABLED,
    DISCREPANCY_BLOCK_TRANSACTION_ENABLED,
    LANDING_COD_BANNER_ENABLED,
    REVAMP_LANDING_V2_ENABLED,
    PROMOTION_BUDGET_ENABLED,
    PROMOTION_TOKEN_ENABLED,
    MEMBERSHIP_RULE_ENABLED,
    COST_SPLIT_ENABLED,
    NON_MEMBERSHIP_ENABLED,
    IDEMPOTENCY_ENABLED,
    IDEMPOTENCY_REGENERATE_UNIQUE_ID_ENABLED,
    DIFF_SERVICE_TYPE_CASHBACK_ENABLED,
    LIMIT_BULK_UPLOAD_USER_ENABLED,
    LIMIT_COD_TRANSACTION_ENABLED,
    NEW_FLOW_KYC_ENABLED,
    MADO_BUKASEND_KYC_ENABLED,
    BLOCK_DUKCAPIL_SUBMITTED_ENABLED,
    MITRA_WALLET_ENABLED,
    MITRA_WALLET_BLOCK_TRANSACTION_ENABLED,
    MITRA_WALLET_ANOTHER_ENTRY_POINT_ENABLED,
    MITRA_WALLET_PAYMENT_METHOD_ENABLED,
    REGISTER_MITRA_AGENT_PHONE_VERIFIED_ENABLED,
    TRANSACTION_LIST_REVAMP_ENABLED,
    BANNER_SLIDER_ENDPOINT_ENABLED,
    MEMBERSHIP_REVAMP_ENABLED,
    CHECK_DUKCAPIL_ENABLED,
    ONGKIR_GOKIL_INTEGRATION_ENABLED,
    MULTIPLE_PROMOTION_INTEGRATION_ENABLED,
    DISABLE_MULTIPLE_PACKAGE_ENABLED,
    BUKAPENGIRIMAN_INVOICE_ENABLED,
  ];

  let requestsByPath = {
    ...requests,
  };

  const cachedValues = {};

  if (IS_SSR) {
    let keys = [];
    const filteredKeys = [];

    if (['/bukasend'].includes(removeTrailingSlash(path)) && !isLoggedIn) {
      keys = ['toggleBukasendDesktop', 'toggleLandingVoucherBannerEnabled', 'toggleRevampLandingV2Enabled'];
    } else if (['/bukasend/kirim-barang'].includes(removeTrailingSlash(path))) {
      keys = ['toggleBukasendDesktop', 'toggleLandingVoucherBannerEnabled'];
    } else if (['/bukasend/mengapa-bukasend', '/bukasend/agen-bukasend'].includes(removeTrailingSlash(path))) {
      keys = ['toggleBukasendDesktop'];
    } else if (path.indexOf('/bukasend/kurir/') >= 0) {
      keys = ['toggleLandingVoucherBannerEnabled'];
    }

    keys.forEach(key => {
      const cacheValue = nodeCache.get(`${cachePrefix}${key}`);
      if (cacheValue !== undefined) {
        cachedValues[key] = cacheValue;
      } else {
        filteredKeys.push(key);
      }
    });

    requestsByPath = filteredKeys.reduce((result, key) => ({ ...result, [key]: requests[key] }), {});
  }

  const neoToggleIds = Object.keys(requestsByPath)
    .filter(key => !state || state[key] === null)
    .map(key => requestsByPath[key]);
  const nonPlatformRequests = neoToggleIds.filter(key => !requestsByPlatform.includes(key));
  const platformRequests = neoToggleIds.filter(key => requestsByPlatform.includes(key));
  const neoToggleRequests = [];

  if (nonPlatformRequests.length) {
    neoToggleRequests.push({
      key: 'nonPlatformRequests',
      body: {
        id: nonPlatformRequests,
        platform: 'other',
      },
    });
  }

  if (platformRequests.length) {
    neoToggleRequests.push({
      key: 'platformRequests',
      body: {
        id: platformRequests,
        platform: neoTogglePlatform,
      },
    });
  }

  let aggregates = neoToggleRequests.reduce((result, req) => {
    const { key, body } = req;
    const params = {
      path: `/_exclusive/neo/toggles`,
      method: 'POST',
      body,
      timeout: 2500,
    };
    return {
      ...result,
      [key]: params,
    };
  }, {});

  if (Object.keys(additionalRequests).length) {
    aggregates = { ...aggregates, ...additionalRequests };
  }

  return {
    requests: requestsByPath,
    platformRequests,
    nonPlatformRequests,
    aggregates,
    cachedValues,
  };
}

module.exports = {
  buildToggleParams,
};
