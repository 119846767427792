const HomePage = () => import('../pages/LandingPage/HomePage.vue');
const HybridFormPage = () => import('../pages/hybrid/FormPage.vue');
const SummaryPage = () => import('../pages/SummaryPage.vue');
const TransactionsPage = () => import('../pages/TransactionsPage/index.vue');
const TransactionDetailPage = () => import('../pages/TransactionDetailPage.vue');
const TrackingPage = () => import('../pages/TrackingPage.vue');
const SendAtOncePage = () => import('../pages/SendAtOncePage');
const PrintInvoicePage = () => import('../pages/PrintInvoicePage.vue');
const InvoicesPage = () => import('../pages/InvoicesPage.vue');
const PrintResiPage = () => import('../pages/PrintResiPage.vue');
const ReturnFormPage = () => import('../pages/ReturnFormPage.vue');
const ReturnSummaryPage = () => import('../pages/ReturnSummaryPage.vue');
const DeliveryInsuranceTermsAndConditions = () =>
  import('../pages/DeliveryInsuranceTermsAndConditions/DeliveryInsuranceTermsAndConditions.vue');
const DeliveryInsuranceClaimTransitionPage = () => import('../pages/DeliveryInsuranceClaimTransitionPage.vue');
const MembershipPage = () => import('../pages/MembershipPage');
const ProfilePage = () => import('../pages/ProfilePage/index.vue');
const ProfileRegisterApi = () => import('../pages/ProfilePage/RegisterApi.vue');
const ProfilePromotionMaterial = () => import('../pages/ProfilePage/PromotionMaterial.vue');
const LeaderboardPage = () => import('../pages/LeaderboardPage.vue');
const PromoDetailPage = () => import('bukasend_desktop/pages/PromoDetailPage/index.vue');
const AddressBookPage = () => import('../pages/AddressBook/index.vue');
const MadoBookingPage = () => import('../pages/Mado/BookingPage');
const MadoPrintResiPage = () => import('../pages/Mado/PrintResiPage');
const MadoTransactionDetailPage = () => import('../pages/Mado/TransactionDetailPage');
const KycPage = () => import('../pages/KycPage/index.vue');
const MitraWalletPage = () => import('../pages/MitraWalletPage/index.vue');
const InvoiceDetailPage = () => import('../pages/InvoiceDetailPage/index.vue');
const InvoicePaymentConfirmationPage = () => import('../pages/InvoicePaymentConfirmationPage/index.vue');

// Extensions
const ShopifyExtensionLanding = () => import('bukasend_mobile/pages/Extensions/Shopify/Landing/index.vue');
const WoocommerceExtensionLanding = () => import('bukasend_mobile/pages/Extensions/Woocommerce/Landing/index.vue');

const DeliveryCostCheckPage = () =>
  import(/* webpackChunkName: "bukasend-delivery-cost-check-page" */ '../pages/DeliveryCostCheckPage/index.vue');
const LandingPage = () => import(/* webpackChunkName: "bukasend-landing-page" */ '../pages/LandingPage/index.vue');
const CourierLandingPage = () =>
  import(/* webpackChunkName: "bukasend-courier-landing-page" */ 'bukasend_desktop/pages/CourierLandingPage/index.vue');
const FulfillmentLandingPage = () =>
  import(/* webpackChunkName: "bukasend-fulfillment-landing-page" */ 'bukasend_mobile/pages/FulfillmentLandingPage');
const B2cLandingPage = () =>
  import(/* webpackChunkName: "bukasend-b2c-landing-page" */ '../pages/B2cLandingPage/index.vue');
const AgentLandingPage = () =>
  import(/* webpackChunkName: "bukasend-agent-landing-page" */ '../pages/AgentLandingPage/index.vue');
const WhyLandingPage = () =>
  import(/* webpackChunkName: "bukasend-why-landing-page" */ '../pages/WhyLandingPage/index.vue');

export default [
  { path: '/', component: LandingPage, name: 'landing_page' },
  { path: '/form', component: HybridFormPage, name: 'form_page' },
  { path: '/summary', component: SummaryPage, name: 'summary_page', props: true },
  { path: '/transactions', component: TransactionsPage, name: 'transactions_page' },
  { path: '/transactions/bulk-print-resi', component: PrintResiPage, name: 'bulk_print_resi_page' },
  { path: '/transactions/:id', component: TransactionDetailPage, name: 'transaction_page' },
  { path: '/transactions/:id/print', component: PrintInvoicePage, name: 'print_invoice_page' },
  { path: '/t/:id/:bookingCode', component: TrackingPage, name: 'tracking_page' },
  { path: '/send-at-once', component: SendAtOncePage, name: 'send_at_once_page' },
  { path: '/invoices', component: InvoicesPage, name: 'invoices_page' },
  { path: '/invoices/:id', component: InvoiceDetailPage, name: 'invoice_page' },
  {
    path: '/invoices/:id/payment-confirmation',
    component: InvoicePaymentConfirmationPage,
    name: 'invoice_payment_confirmation_page',
  },
  { path: '/invoices/:id/print-resi', component: PrintResiPage, name: 'print_resi_page' },
  { path: '/delivery-cost-check', component: DeliveryCostCheckPage, name: 'delivery_cost_check_page' },
  { path: '/return-form', component: ReturnFormPage, name: 'return_form_page' },
  { path: '/return-summary', component: ReturnSummaryPage, name: 'return_summary_page' },
  {
    path: '/insurance/syarat-dan-ketentuan',
    component: DeliveryInsuranceTermsAndConditions,
    name: 'snk_insurance_page',
  },
  {
    path: '/insurance/claim',
    component: DeliveryInsuranceClaimTransitionPage,
    name: 'delivery_insurance_claim_page',
  },
  {
    path: '/membership',
    component: MembershipPage,
    name: 'membership_page',
  },
  {
    path: '/home',
    component: HomePage,
    name: 'home_page',
  },
  {
    path: '/profile',
    component: ProfilePage,
    name: 'profile_page',
  },
  {
    path: '/register-api',
    component: ProfileRegisterApi,
    name: 'register_api',
  },
  {
    path: '/promotion-material',
    component: ProfilePromotionMaterial,
    name: 'promotion_material',
  },
  {
    path: '/leaderboard',
    component: LeaderboardPage,
    name: 'leaderboard_page',
  },
  {
    path: '/promos/:id',
    component: PromoDetailPage,
    name: 'promo_detail_page',
  },
  {
    path: '/vouchers/:code',
    component: PromoDetailPage,
    name: 'voucher_detail_page',
  },
  {
    path: '/extensions/shopify',
    component: ShopifyExtensionLanding,
    name: 'shopify_extension_landing',
  },
  {
    path: '/extensions/woocommerce',
    component: WoocommerceExtensionLanding,
    name: 'woocommerce_extension_landing',
  },
  {
    path: '/kurir/:courierCode',
    component: CourierLandingPage,
    name: 'courier_landing_page',
  },
  {
    path: '/address-book',
    component: AddressBookPage,
    name: 'address_book_page',
  },
  {
    path: '/mado/booking',
    component: MadoBookingPage,
    name: 'mado_booking_page',
  },
  {
    path: '/mado/print-resi/:id',
    component: MadoPrintResiPage,
    name: 'mado_print_resi_page',
  },
  {
    path: '/mado/transactions/:id',
    component: MadoTransactionDetailPage,
    name: 'mado_transaction_page',
  },
  {
    path: '/business',
    component: FulfillmentLandingPage,
    name: 'fulfillment_landing_page',
  },
  {
    path: '/kirim-barang',
    component: B2cLandingPage,
    name: 'b2c_landing_page',
  },
  {
    path: '/agen-bukasend',
    component: AgentLandingPage,
    name: 'agent_landing_page',
  },
  {
    path: '/mengapa-bukasend',
    component: WhyLandingPage,
    name: 'why_landing_page',
  },
  {
    path: '/kyc',
    component: KycPage,
    name: 'kyc_page',
  },
  {
    path: '/mitra-wallet',
    component: MitraWalletPage,
    name: 'mitra_wallet_page',
  },
  { path: '*', redirect: { name: 'landing_page' } },
];
