/* eslint-disable import/prefer-default-export */
import { getUserId36 } from 'shared_js/helpers/user-helper';
import { platform } from 'shared_js/helpers/user-agent-helper';
import { IS_SSR } from 'shared_js/constant/general';

export const track = (eventName, params = {}) => {
  if (IS_SSR) {
    return;
  }
  const { Tracker } = global;
  if (!Tracker) {
    return;
  }
  if (Tracker.$config.dev) {
    console.info('trackEvent', eventName, params);
    return;
  }
  const userId = getUserId36();
  const defaultParameters = {
    evn: eventName,
    ui: userId,
  };
  const { origin: baseUrl } = new URL(global.APP.internalTrackerUrl);
  Tracker.config({
    baseUrl,
    dev: process.env.NODE_ENV !== 'production',
    platform: platform(),
  });
  Tracker.event(Object.assign(defaultParameters, params));
};
