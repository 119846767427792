/* eslint-disable */
const API_BASE_URL = global.serverApp ? global.serverApp.apiURL : global.APP ? global.APP.apiURL : '';
const INTERFACE_KEY = 'xhrBukaSend';
const IS_DEV_MODE = process.env.NODE_ENV !== 'production';
const WRAP_API = global.API ? global.API.wrap : null;
const USER_ID =
  global.serverApp && global.serverApp.user
    ? global.serverApp.user.id.toString(36)
    : global.USER
    ? global.USER.id36
    : '';

const CAROUSEL_DEFAULT_OPTIONS = Object.freeze({
  infinite: false,
  autoplay: false,
});

const MAX_WEIGHT = 125000;
const MAX_TRANSACTION = 30;
const MAX_ORDER = 10;

const COURIER_SERVICE = {
  pickup: 'Dijemput kurir',
  dropoff: 'Diantar sendiri',
  dropoff_cashless: 'Diantar sendiri',
};

const COURIER_SERVICE_TYPES = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff',
  DROPOFF_CASHLESS: 'dropoff_cashless',
};

const BUKASEND_TRANSACTION_TYPE = 'buka-pengiriman';

const UPSELL_RETUR_CASHBACK_KEY = 'upsell_retur_cashback';
const RESURRECTED_USER_CASHBACK_KEY = 'resurrected_user_cashback';
const PARTNER_LOGISTIC_CASHBACK_KEY = 'partner_logistic_cashback';
const CASHBACK_ONGKIR_GOKIL_KEY = 'cashback_ongkir_gokil';

const PROMO_TYPES = {
  [UPSELL_RETUR_CASHBACK_KEY]: 'Upsell Retur',
  [RESURRECTED_USER_CASHBACK_KEY]: 'Resurrected User',
  [PARTNER_LOGISTIC_CASHBACK_KEY]: 'Promo Kurir',
  [CASHBACK_ONGKIR_GOKIL_KEY]: 'Ongkir Gokil',
};

const PROMO_TYPES_WITH_CASHBACK = [
  PARTNER_LOGISTIC_CASHBACK_KEY,
  RESURRECTED_USER_CASHBACK_KEY,
  UPSELL_RETUR_CASHBACK_KEY,
  CASHBACK_ONGKIR_GOKIL_KEY,
];

const HIDDEN_IF_CASHBACK_ZERO_PROMO_TYPES = [PARTNER_LOGISTIC_CASHBACK_KEY, CASHBACK_ONGKIR_GOKIL_KEY];

const CONTACT_CHANNELS = {
  wa: 'WA',
  line: 'Line',
  ig: 'IG',
  fb: 'FB',
  lainnya: 'lainnya',
};

const DEFAULT_ERROR_MESSAGE = 'Terjadi kesalahan, silahkan coba kembali!';

const STATIC_DELIVERY_TYPES = {
  A: 'pickup',
  B: 'dropoff',
};

const STATIC_SERVICE_TYPES = {
  1: 'J&T REG',
  2: 'GrabExpress Same Day',
  3: 'GrabExpress Instant',
  4: 'NINJA REG',
  5: 'NINJA FAST',
  6: 'Pos Kilat Khusus',
  7: 'Pos Next Day',
  8: 'GO-SEND Same Day',
  9: 'GO-SEND Instant',
  10: 'SiCepat REG',
  11: 'SiCepat BEST',
  12: 'Paxel Same Day',
  13: 'Lion Parcel REGPACK',
  14: 'Lion Parcel ONEPACK',
  15: 'JNE REG',
  16: 'JNE YES',
};

const PICKUP_SERVICES = [
  'Grab Instant', // TODO: remove this once GrabExpress already up
  'Grab Same Day', // TODO: remove this once GrabExpress already up
  'GrabExpress Instant',
  'GrabExpress Same Day',
  'J&T REG',
  'Lion Parcel REGPACK',
  'Lion Parcel ONEPACK',
  'NINJA REG',
  'NINJA FAST',
  'Paxel Same Day',
  'SiCepat REG',
  'SiCepat BEST',
];

const DROPOFF_SERVICES = [
  'J&T REG',
  'Lion Parcel REGPACK',
  'Lion Parcel ONEPACK',
  'Pos Kilat Khusus',
  'Pos Next Day',
  'SiCepat REG',
  'SiCepat BEST',
  'JNE REG',
  'JNE YES',
];

const PAYMENT_METHOD_NAMES = {
  deposit: 'BukaDompet',
  dana: 'DANA',
  atm: 'Transfer',
  wallet: 'BukaDompet',
  transfer: 'Transfer',
  virtual_account: 'Transfer Virtual Account',
  credit_card: 'Kartu Visa/Mastercard/JCB/AMEX',
  bca_klikpay: 'BCA KlikPay (KlikBCA Individu)',
  mandiri_clickpay: 'Mandiri Clickpay',
  mandiri_ecash: 'LinkAja',
  cimb_clicks: 'CIMBClicks/RekPonsel/QRGoMobile',
  indomaret: 'Indomaret',
  alfamart: 'Alfamart',
  pospay: 'Pos Indonesia',
  voucher: 'Voucher',
  kredivo: 'Kredivo',
  akulaku: 'Akulaku',
  cardless_installment: 'Cicilan Tanpa Kartu Kredit',
  bri_e_pay: 'BRI E-Pay',
  bca_oneklik: 'OneKlik',
  xendit: 'Xendit',
  bukasend_invoice: 'BukaSend Invoice',
};

const WEIGHT_UNITS = {
  KG: { value: 'kg', label: 'Kilogram' },
  GR: { value: 'gr', label: 'Gram' },
};

const BUKASEND_INSURANCE_POLICIES = {
  pending: 'Pending',
  activated: 'Terlindungi',
  claim_submitted: 'Klaim diajukan',
  claim_processed: 'Klaim diproses',
  claim_approved: 'Klaim disetujui',
  claim_paid: 'Klaim dibayar',
  claim_cancelled: 'Klaim dibatalkan',
  claim_rejected: 'Klaim ditolak',
  expired: 'Klaim tertunda',
};

const BUKASEND_CASHBACK_LABEL_DEFAULT = {
  '3pl': {
    tnc: [
      'Program ini dimulai pada tanggal 1 Oktober 2020.',
      'Promo ini hanya berlaku untuk pengiriman melalui BukaSend, bukan untuk transaksi Marketplace Bukalapak.',
      'Cashback hingga 35% didapatkan langsung tanpa memasukan kode promo.',
      'Promo ini hanya berlaku untuk pengiriman paket dengan kurir J&T Express, Ninja Xpress, Sicepat Ekspres, Lion Parcel, dan JNE Express.',
      'Pengguna yang melakukan pengiriman dengan BukaSend akan mendapatkan cashback ongkos kirim hingga 35% untuk setiap transaksi yang berhasil dikirim dengan jasa pengiriman  J&T Express, Ninja Xpress, Sicepat Ekspres, Lion Parcel, dan JNE Express selama periode promosi. Berhasil dalam hal ini adalah barang sudah sampai dan terkonfirmasi diterima oleh penerima.',
      'Cashback berupa saldo BukaDompet Bukalapak.',
      'Cashback hanya berlaku untuk nomor-nomor resi atau airway-bill yang valid menurut oleh sistem Bukalapak.',
      'Cashback akan diberikan secara kumulatif ke saldo BukaDompet milik Pengguna di tanggal 10 di bulan selanjutnya.',
      '1 (satu) cashback hanya untuk 1 (satu) resi valid. Resi yang sudah dipakai tidak bisa dipakai pada transaksi lainnya.',
      'Cashback tidak akan diberikan jika resi BukaSend digunakan di Marketplace Bukalapak.',
      'Bukalapak berhak melakukan tindakan-tindakan yang diperlukan tanpa pemberitahuan sebelumnya. Tindakan tersebut seperti pembatalan cashback atau tindakan lainnya apabila ditemukan kecurangan dari pengguna, termasuk upaya untuk mendapatkan cashback dari transaksi fiktif atau tindakan kecurangan lain yang menguntungkan pihak-pihak tertentu.',
      'Bukalapak berhak mengubah syarat dan ketentuan promo sewaktu-waktu.',
    ],
    landing_page: {
      title: 'Hore, bisa dipake!',
      desc: 'Cashback hingga 35% tiap kirim paket di BukaSend',
    },
    booking_form_page: {
      header:
        'Kamu dapet promo <strong class="bl-text--bold">cashback ongkir hingga 35%</strong> tiap kirim paket pakai BukaSend',
    },
  },
};

const BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT = [
  'Fashion',
  'Dokumen',
  'Buku',
  'Obat & Herbal',
  'Kecantikan',
  'Mainan',
  'Makanan',
  'Sparepart',
  'Alat Kesehatan',
];

const BUKASEND_MISC_CONFIG_DEFAULT = {
  changeLog: {
    url: '',
  },
  courier: {
    contacts: {
      jnt: '021-8066-1888',
      lionparcel: '021-2258-0868',
      ninjavan: '021-2926-4120',
      paxel: '0855-7467-0318',
      jne: '021-2927-8888',
      sicepat: '021-5020-0050',
      indah: '021-8062-7000',
    },
    whatsapp: {},
  },
  pojokBisnis: {
    registerUrl: '',
  },
  bulkSendDraftLimit: 30,
  bulkPrintResiLimit: 30,
};

const BUKASEND_REBOOK_CONFIG_DEFAULT = {
  rebookEnabled: false,
  resendEnabled: false,
  rebookableCourierServices: [],
  resendableCourierServices: [],
  rebookMessages: {},
};

const BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT = [
  'Grab Instant',
  'Grab Same Day',
  'Rocket Delivery',
  'GO-SEND Same Day',
  'GO-SEND Instant',
];

const MEMBERSHIP_TIERS = {
  nonmember: 'NONMEMBER',
  standard: 'STANDARD',
  silver: 'SILVER',
  gold: 'GOLD',
  platinum: 'PLATINUM',
};

const MEMBERSHIP_TIER_NAMES = {
  NONMEMBER: 'Calon Member',
  STANDARD: 'Standard',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
};

const BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT = {
  start_date: 25,
  start_hour: 0,
  end_date: 25,
  end_hour: 0,
  base: {
    STANDARD: 50,
    SILVER: 50,
    GOLD: 50,
  },
};

const CONNECTION_ERROR_TYPES = { TIMEOUT: 'timeout', OFFLINE: 'offline', OTHER: 'other' };

const PROHIBITED_TNC = {
  descriptions: [
    'BukaSend mengedepankan layanan pengiriman yang aman dan nyaman bagi seluruh pihak. Oleh karena itu, pengguna perlu memperhatikan jenis barang apa saja yang boleh dan tidak boleh, sehingga tidak terjadi hal-hal yang tidak diinginkan.',
    'Berdasarkan peraturan dan perundang-undangan yang berlaku di Republik Indonesia, dan kebijakan internal BukaSend, dan/atau Kebijakan Platform Distribusi Aplikasi, pengguna dihimbau untuk tidak mengirim <strong>Barang-Barang Terlarang</strong>, yaitu barang dan/atau jasa yang tergolong berbahaya, melanggar hukum, mengancam, melecehkan, menghina, memfitnah, mengintimidasi, dan mengganggu privasi atau hak asasi manusia lainnya.',
    'Berdasarkan hal-hal yang disebutkan di atas, berikut ini adalah daftar barang-barang terlarang untuk pengiriman melalui platform BukaSend:',
  ],
  list: [
    'Segala jenis obat-obatan maupun zat-zat lain yang dilarang ataupun dibatasi peredarannya menurut ketentuan hukum yang berlaku, termasuk namun tidak terbatas pada ketentuan Undang-Undang Narkotika, Undang-Undang Psikotropika, dan Undang-Undang Kesehatan. Termasuk dalam ketentuan ini adalah narkotika, obat keras, obat bius dan sejenisnya, serta obat-obatan yang tidak terdaftar di dinas kesehatan, dan Badan Pengawas Obat dan Makanan (BPOM), serta obat berlogo "K" (resep dokter);',
    'Senjata api, kelengkapan senjata api, replika senjata api, <i>airsoft gun, air gun</i>, dan peluru atau sejenis peluru, senjata tajam, serta jenis senjata lainnya;',
    'Barang dewasa penunjang kegiatan seksual termasuk namun tidak terbatas pada obat kuat, obat perangsang, alat bantu seks, pornografi, dan obat-obatan dewasa, kecuali untuk alat kesehatan (kontrasepsi) yang diizinkan untuk diperjualbelikan oleh peraturan hukum yang berlaku di Indonesia;',
    'Barang yang mudah meledak, menyala, dan/atau terbakar sendiri;',
    'Bagian organ manusia dan/atau perdagangan orang;',
    'Cinderamata berbahan hewan yang dilindungi;',
    'Barang terkait perjudian;',
    'Barang curian;',
    'Barang mistis, termasuk benda- benda yang berkekuatan gaib dan/atau memberikan ilmu kesaktian seperti jimat, keris, dan lain-lain;',
    'Segala jenis hewan peliharaan;',
    'Pembuka kunci dan penunjang tindakan perampokan/pencurian;',
    'Otomotif (mobil dan motor) kecuali terdapat kerja sama resmi dengan BukaSend;',
    'Bahan yang diklasifikasikan sebagai Bahan Berbahaya menurut Peraturan Menteri Perdagangan yang berlaku;',
    'Segala jenis barang yang bertentangan dengan peraturan pengiriman barang Indonesia;',
    'Segala jenis barang yang dilarang atau bertentangan dengan ketentuan platform distribusi aplikasi.',
  ],
};

const INVOICE_STATUS_LABELS = {
  payment_chosen: 'Menunggu Metode Bayar',
  pending: 'Menunggu Dibayar',
  paid: 'Dibayar',
  remitted: 'Selesai',
  refunded: 'Dikembalikan',
  cancelled: 'Dibatalkan',
  expired: 'Kedaluwarsa',
};

const SYNC_ORDER_STATUS = {
  SUCCESS: 'success',
  NO_SYNC: 'no_sync',
  INCOMPLETE: 'incomplete',
  FAILED: 'failed',
  INVALID_TOKEN: 'invalid_token',
  SYNCING: 'synchronizing_orders',
};

const TRANSACTIONS_FILTERS = {
  BUKASEND: 'bukasend',
  MADO: 'mado',
};

const PROMO_USER_TYPES = {
  CURRENT_USER_3PL: '3pl',
};

const LANDING_FAQ = {
  parent: [
    {
      name: 'what',
      title: 'Apa itu BukaSend?',
      content:
        'BukaSend adalah fitur pengiriman yang dapat diakses melalui aplikasi Bukalapak/Mitra Bukalapak dan dapat dimanfaatkan siapa pun untuk mengirim paket tanpa perlu pergi ke agen. Melalui fitur ini pengguna akan mendapatkan berbagai macam kemudahan seperti, mendapatkan banyak pilihan jasa pengiriman, pembayaran secara aman, paket yang langsung dijemput oleh kurir, dan mendapatkan live tracking pengiriman. BukaSend sangat membantu dalam pemesanan kurir melalui handphone dan memudahkan semua orang termasuk penjual online shop di mana pun.',
    },
    {
      name: 'why',
      title: 'Kenapa harus pake BukaSend dibanding yang lain?',
      content:
        '<ul><li>Kurirnya banyak, tinggal pilih yang kamu paling cocok</li><li>Tinggal order kirim paket, bisa rebahan di rumah barang terkirim dengan fitur pickup</li><li>Cashbacknya melimpah, jadi banyak untung deh</li><li>Melayani berbagai macam jenis paket, kalau kamu inget mau kirim paket, inget BukaSend</li><li>Dan masih banyak lagi</li></ul>',
    },
    {
      name: 'courier',
      title: 'Kurir Apa aja yang tersedia di BukaSend?',
      content: 'Mulai dari JNE, J&T, SiCepat, AnterAja, Lion Parcel, JTR, Ninja Xpress, Wahana dan masih banyak lagi',
    },
    {
      name: 'send_package',
      title: 'Apakah BukaSend bisa mengirimkan paket ke Luar Negeri?',
      content:
        'Untuk saat ini BukaSend melayani pengiriman domestik saja, nanti kedepannya apabila sudah bisa melayani pengiriman ke luar negeri akan kami informasikan kembali.',
    },
    {
      name: 'send_time',
      title: 'Berapa lama pengiriman apabila menggunakan BukaSend?',
      content:
        'Tergantung dengan ekspedisi yang kamu pilih! BukaSend menyediakan berbagai macam ekspedisi yang sudah terpercaya untuk mengirimkan paket milikimu. Berikut merupakan kategori pengiriman BukaSend:<br/><ul><li>Reguler: Mengikut</li><li>Besok Sampai: Paket yang dikirimkan akan sampai di keesokan hari</li><li>Sehari Sampai: Paket yang dikirimkan akan sampai pada hari yang sama dengan catatan pengiriman dilakukan sebelum jam kerja pengiriman pada hari tersebut tutup.</li><li>Pengiriman dalam jumlah berat/Cargo</li></ul>',
    },
  ],
  b2c: [
    {
      name: 'how_to_register',
      title: 'Bagaimana cara daftar ke BukaSend?',
      content:
        'Kamu cukup masuk ke halaman BukaSend yang ada di aplikasi Bukalapak, klik menu "Daftar jadi agen" yang ada pada tab "Profil" di kategori "Lainnya", dan klik tombol "Daftar sekarang". Setujui syarat & ketentuannya lalu lengkapi data diri juga info tempat usaha. Selesai, kamu tinggal menunggu selama 72jam sampai datamu selesai diverifikasi',
    },
    {
      name: 'who_can_use',
      title: 'Siapa saja yang bisa pakai BukaSend?',
      content:
        'Siapa pun bisa pakai BukaSend. Mulai dari pedangang online, pengusaha UMKM, sampai pemilik toko offline bisa menggunakan fitur ini.',
    },
    {
      name: 'send_food',
      title: 'Apakah BukaSend menyediakan jasa pengiriman untuk makanan?',
      content:
        'Tentu saja! Kamu bekerja sama dengan Grab Express dan Paxel yang menyediakan jasa pengiriman same day dan juga instan yang bisa kamu pilih sesuai dengan kebutuhan.',
    },
    {
      name: 'send_many',
      title: 'Bisakah kirim barang dalam jumlah banyak pakai BukaSend?',
      content:
        'Tentu bisa. BukaSend menyediakan jasa pengiriman JNE Trucking dengan harga yang kompetitif. Jadi kamu bisa mengirimkan barang dalam jumlah banyak atau barang besar menggunakan kargo ke berbagai wilayah di Indonesia.',
    },
    {
      name: 'share_resi',
      title: 'Bagaimana cara membagikan nomor resi ke pelanggan saya?',
      content:
        'Setelah paket sudah berhasil diambil oleh kurir/ diantar sendiri ke kurir, kamu dapat membagikan nomor resi dan link pelacakan (live tracking) kepada penerima dengan pilih Lihat Detail pada transaksi yang dimaksud kemudian klik salin link.',
    },
  ],
  b2b: [
    {
      name: 'business_requirement',
      title: 'Apakah saya bisa menggunakan BukaSend for Business untuk logistik bisnis saya?',
      content:
        'Semua bisnis yang berbentuk PT bisa menggunakan layanan BukaSend for Business. Kamu bisa pilih dari salah satu fitur yang tersedia yaitu Plugin Ecommerce seperti Shopify, Shipping Dashboard (Non API), dan Integrasi API.',
    },
    {
      name: 'bukasend_shopify',
      title: 'Saya menggunakan Shopify untuk bisnis saya, apakah saya bisa menggunakan BukaSend?',
      content:
        'Tentu bisa. Kamu cukup install BukaSend di Shopify Admin, setelah itu BukaSend bisa langsung digunakan.',
    },
    {
      name: 'partner_registration',
      title: 'Bagaimana cara mendaftar sebagai partner BukaSend?',
      content:
        'Kamu cukup klik tombol "Daftar Sekarang" yang ada di landing page BukaSend. Kamu juga konsultasi lewat WhatsApp dengan klik tombol whatsapp yang ada di landing page.',
    },
    {
      name: 'bukasend_dashboard',
      title: 'Bagaimana cara memantau proses pengiriman barang transaksi saya?',
      content:
        'Kamu bisa menggunakan fitur dashboard. Silakan akses BukaSend melalui desktop/mobile untuk masuk ke fiturnya.',
    },
    {
      name: 'payment_scheme',
      title: 'Bagaimana skema pembayaran BukaSend for Business?',
      content:
        'Skema pembayaran BukaSend for Business adalah dengan monthly invoice untuk fitur Integrasi API dan Shipping Dashboard (Non API). Namun, jika kamu menggunakan fitur Shopify dan ingin mendapatkan skema pembayaran monthly invoice, silakan langsung hubungi tim BukaSend dengan klik tombol WhatsApp yang ada di pojok kanan bawah layar kamu atau kamu bisa kirim email ke logistic@bukalapak.com dengan subject "[Nama Company] - Daftar BukaSend For Business".',
    },
  ],
  agent: [
    {
      name: 'why_become_agent',
      title: 'Kenapa harus jadi Agen BukaSend?',
      content:
        'Dengan jadi Agen BukaSend, kamu bisa dapat komisi berupa cashback di setiap pengiriman. Selain itu kamu bisa memiliki akses untuk mendownload materi promosi offline seperti spanduk atau banner BukaSend yang bisa dipajang di tokomu serta akses ke halaman Ensiklopedia agen untuk mendapatkan tips dan trik agar kamu bisa menjadi agen yang sukses.',
    },
    {
      name: 'register_bukasend',
      title: 'Apakah saya perlu melakukan Registrasi di Bukasend walau akun saya berstatus Agen/Mitra?',
      content:
        'Perlu agar akun kamu tidak ter-downgrade secara otomatis ke akun Reguler. Jika hal ini terjadi, maka kamu tidak bisa mengakses fitur-fitur dan benefit yang dikhususkan untuk Agen.',
    },
    {
      name: 'courier_logo',
      title: 'Apakah saya bisa memasang logo kurir tertentu pada spanduk?',
      content: 'Agen BukaSend tidak diperkenankan memasang logo kurir apapun pada seluruh materi offline BukaSend.',
    },
    {
      name: 'prohibited_product',
      title: 'Barang apa yang tidak bisa diterima oleh BukaSend?',
      content:
        'Agen BukaSend tidak boleh menerima kiriman barang dalam kategori berbahaya dan kategori larangan undang-undang yang berlaku seperti; bahan mudah terbakar, bahan mudah meledak, alkohol, senjata, psikotropika dan obat-obatan, binatang hidup, barang biologis yang menyebabkan penularan penyakit, barang berharga, dan lain-lain',
    },
    {
      name: 'shipping_cost',
      title: 'Berapa ongkos kirim barang di BukaSend?',
      content:
        'Ongkos kirim barang di BukaSend tergantung dari layanan kurir yang dipilih, jarak pengiriman dan berat paket. Silahkan cek link berikut untuk mengetahui ongkos kirim barang di BukaSend: https://www.bukalapak.com/bukasend/delivery-cost-check',
    },
  ],
  why: [
    {
      name: 'what',
      title: 'Apa itu BukaSend?',
      content:
        'BukaSend adalah fitur pengiriman yang dapat diakses melalui aplikasi Bukalapak/Mitra Bukalapak dan dapat dimanfaatkan siapa pun untuk mengirim paket tanpa perlu pergi ke agen. Melalui fitur ini pengguna akan mendapatkan berbagai macam kemudahan seperti, mendapatkan banyak pilihan jasa pengiriman, pembayaran secara aman, paket yang langsung dijemput oleh kurir, dan mendapatkan live tracking pengiriman. BukaSend sangat membantu dalam pemesanan kurir melalui handphone dan memudahkan semua orang termasuk penjual online shop di mana pun.',
    },
    {
      name: 'why',
      title: 'Kenapa harus pake BukaSend dibanding yang lain?',
      content:
        '<ul><li>Kurirnya banyak, tinggal pilih yang kamu paling cocok</li><li>Tinggal order kirim paket, bisa rebahan di rumah barang terkirim dengan fitur pickup</li><li>Cashbacknya melimpah, jadi banyak untung deh</li><li>Melayani berbagai macam jenis paket, kalau kamu inget mau kirim paket, inget BukaSend</li><li>Dan masih banyak lagi</li></ul>',
    },
    {
      name: 'who',
      title: 'Siapa saja yang bisa pakai BukaSend? ',
      content:
        'Siapa pun bisa pakai BukaSend. Mulai dari pedangang online, pengusaha UMKM, sampai pemilik toko offline bisa menggunakan fitur ini.',
    },
    {
      name: 'agent',
      title: 'Kenapa harus jadi Agen BukaSend?',
      content:
        'Dengan jadi Agen BukaSend, kamu bisa dapat komisi berupa cashback di setiap pengiriman. Selain itu kamu bisa memiliki akses untuk mendownload materi promosi offline seperti spanduk atau banner BukaSend yang bisa dipajang di tokomu serta akses ke halaman Ensiklopedia agen untuk mendapatkan tips dan trik agar kamu bisa menjadi agen yang sukses.',
    },
    {
      name: 'business',
      title: 'Apakah saya bisa menggunakan BukaSend for Business untuk logistik bisnis saya?',
      content:
        'Semua bisnis yang berbentuk PT bisa menggunakan layanan BukaSend for Business. Kamu bisa pilih dari salah satu fitur yang tersedia yaitu Plugin Ecommerce seperti Shopify, Shipping Dashboard (Non API), dan Integrasi API.',
    },
  ],
};

const generateCourierLandingUrl = (courierCode, courierName) => {
  const url = `/bukasend/kurir/${courierCode}`;

  return `<a href="${url}" class="u-txt--underline u-txt--gray-60">${courierName}</a>`;
};

const COURIER_LANDING_PAGES = [
  {
    key: 'sicepat',
    name: 'SiCepat',
  },
  {
    key: 'jne',
    name: 'JNE',
  },
  {
    key: 'jnt-express',
    name: 'J&T',
  },
  {
    key: 'jne',
    name: 'JTR',
  },
  {
    key: 'lion-parcel',
    name: 'Lion Express',
  },
  {
    key: 'grab-express',
    name: 'GrabExpress',
  },
  {
    key: 'wahana',
    name: 'Wahana',
  },
  {
    key: 'ninja-xpress',
    name: 'Ninja Express',
  },
  {
    key: 'anteraja',
    name: 'Anteraja',
  },
  {
    key: 'paxel',
    name: 'Paxel',
  },
  {
    key: 'sap-express',
    name: 'SAP Express',
  },
  {
    key: 'id-express',
    name: 'ID Express',
  },
];

const COURIER_PAGES_HTML = COURIER_LANDING_PAGES.map(courier => generateCourierLandingUrl(courier.key, courier.name))
  .join(', ')
  .replace(/, ([^,]*)$/, ' and $1');

const LANDING_SEO_FOOTER = {
  parent: [
    {
      title: 'Kirim Paket Lebih Mudah dan Murah',
      content:
        'Bersama BukaSend, para pelaku bisnis online baik pelapak di Bukalapak, pedagang online non e-commerce ataupun umkm tidak perlu lagi khawatir dalam proses pengiriman barang kepada konsumen, BukaSend memberi kemudahan pada para pelanggan dengan ketersediaan opsi pengiriman kurir ekspedisi yang lengkap dan tarif ongkos kirim yang murah.',
    },
    {
      title: 'Keuntungan Pakai Jasa Pengiriman yang Lengkap',
      content:
        'Bagi pelanggan, ketersediaan opsi pengiriman barang yang lengkap, tarifnya murah, cepat sampai, terjamin, serta dekat dari rumah (tempat tinggal) mereka akan lebih menyenangkan. Selain melihat ketersediaan opsi pengiriman, pelanggan juga akan merasa puas dan senang bertransaksi dengan Anda apabila pesanan mereka dikemas dan dikirim sesegera mungkin. Karena, semakin cepat pesanan mereka di-packing dan dikirim, maka akan semakin cepat pula sampainya.',
    },
    {
      title: 'Kirim Paket Pakai BukaSend',
      content:
        'Keterbatasan kendaraan operasional, cuaca buruk, atau bisa juga disebabkan karena pesanan yang membludak membuat pelapak tidak  bisa langsung kirim paket sesaat setelah menerima pesanan. Untungnya, berbagai kendala di atas bisa diatasi dengan menggunakan fitur BukaSend.<br/><br/>BukaSend bisa  digunakan oleh segala pengguna mulai dari pengguna ataupun non-pengguna Bukalapak seperti, UMKM, corporate (B2B), ataupun pelaku usaha pada umumnya, anda bisa menggunakan fitur ini untuk mengirim barang yang dibeli lewat sosial media maupun platform lainnya. Itu artinya, kirim paket di luar transaksi e-commerce juga bisa diproses dengan menggunakan BukaSend.<br/><br/>Disamping menyediakan opsi jasa pengiriman barang murah dan lengkap, BukaSend juga menawarkan berbagai kemudahan yang pasti dapat diandalkan untuk memperlancar dan mengembangkan bisnis. Misal, pengguna BukaSend  tidak perlu keluar rumah untuk kirim barang karena BukaSend menyediakan opsi penjemputan paket oleh kurir yang dipilih, menyediakan fitur live tracking untuk mengetahui posisi barang, hingga bisa kirim paket sekaligus. Pelanggan akan langsung mendapat SMS notifikasi yang juga berisi kode tracking dengan metode pembayaran cashless.',
    },
    {
      title: 'Mitra Kurir Ekspedisi BukaSend',
      content: `Saat ini BukaSend sudah memiliki beberapa mitra jasa pengiriman barang yang mudah dan terpercaya dengan tarif ongkir yang murah, yaitu: ${COURIER_PAGES_HTML}.<br/><br/>Jasa pengiriman terpadu BukaSend akan memudahkan proses pengiriman barang anda kepada pelanggan yang akan memperlancar dan mempercepat kemajuan bisnis anda. Karena, layanan kirim paket BukaSend yang prima akan memuaskan dan meningkatkan kepercayaan pelanggan pada bisnis Anda.`,
    },
  ],
  b2c: [
    {
      title: 'BukaSend, Kirim Barang Tanpa Hambatan',
      content:
        'Kini kamu gak perlu lagi kesulitan dalam mengirim barang, karena BukaSend menyediakan beragam solusi pengiriman dengan harga ongkir yang murah. Nikmati berbagai keunggulan juga kemudahan mengirim paket dengan BukaSend! Kamu bisa pilih salah satu dari banyak pilihan kurir ekspedisi yang tersedia di BukaSend dan kamu juga bisa kirim banyak paket sekaligus lho! Kamu bisa cek tarif ongkos kirimnya dulu langsung di BukaSend. Gak cuma itu di BukaSend barang kamu pasti sampai lebih cepat karena ada garansi tepat waktu.',
    },
    {
      title: 'Siapa Saja Bisa Pakai BukaSend',
      content:
        'BukaSend bisa digunakan oleh siapa saja, mulai dari pengguna atau non-pengguna Bukalapak seperti UMKM, penjual social commerce, sampai corporate (B2B). Kamu bisa menggunakan fitur ini untuk cek ongkir, kirim paket, mengirim barang yang dibeli lewat sosial media maupun platform lainnya. BukaSend juga menawarkan solusi untuk mempermudah kebutuhan logistik bisnis kamu seperti dashboard, integrasi API, serta sewa gudang untuk penyimpanan barang jualan. Gak cuma itu, kamu pun bisa memanfaatkan fitur ini untuk memperoleh penghasilan tambahan dengan menjadi Agen BukaSend.',
    },
    {
      title: 'Keuntungan Pakai Solusi Logistik BukaSend',
      content:
        'BukaSend memiliki banyak keuntungan seperti ada banyak pilihan jasa pengiriman dan bisa minta kurir jemput paket langsung ke rumah. Jadi gak perlu lagi repot-repot anter paket ke gerai ekspedisi. Kamu juga bisa kirim banyak paket sekaligus dan ada pilihan COD juga. BukaSend juga punya banyak promo menarik yang bikin pengiriman jadi makin hemat setiap harinya. Buat kamu para pelaku bisnis, BukaSend bisa membantu menyediakan solusi logistik untuk bisnismu seperti integrasi API, dashboard, dan plugin ecommerce, serta jasa sewa gudang.',
    },
    {
      title: 'Pilihan Mitra Ekspedisi BukaSend Terlengkap',
      content: `Ada banyak mitra ekspedisi yang bergabung di BukaSend seperti ${COURIER_PAGES_HTML}. Pengiriman paketnya terjamin, kurirnya tersedia di seluruh indonesia, tarif ongkirnya murah, dan paket bisa sampai cepat juga tepat waktu. Kamu bisa bagikan link tracking paket ke pelangganmu agar ia bisa cek resi dan lacak paketnya sendiri. BukaSend memudahkan proses shipping barang kamu ke pelanggan dan membantu memudahkan operasional logistik bisnis kamu`,
    },
  ],
  agent: [
    {
      title: 'Agen Pengiriman Barang BukaSend',
      content:
        'Siapapun bisa jadi agen pengiriman barang dengan mendaftar di BukaSend. Caranya mudah, gak pakai lama dan yang paling penting semuanya gratis. Jadi kamu tidak perlu bayar biaya atau mengeluarkan modal apapun untuk mulai jadi agen. Setelah resmi jadi agen, kamu bisa dapat akses ke training center yang bisa bantu bikin bisnis agen pengiriman BukaSend kamu jadi makin cuan. Dengan jadi agen BukaSend, kamu bisa punya bisnis jadi agen ekspedisi gratis.',
    },
    {
      title: 'BukaSend banyak pilihan kurirnya!',
      content: `Ada banyak mitra ekspedisi yang bergabung di BukaSend seperti ${COURIER_PAGES_HTML}. Jadi agen BukaSend merupakan cara jadi agen SiCepat dan cara jadi agen JnT secara cepat dan mudah. Dengan banyak pilihan kurir ekspedisi yang sudah bergabung di BukaSend, siapapun bisa punya bisnis agen pengiriman gratis. Pelangganmu bisa titip paket ke rumah dan kamu tinggal tunggu agen pengiriman ambil paketnya langsung ke rumahmu.`,
    },
    {
      title: 'Bisnis Agen BukaSend Pasti Cuan',
      content: `Kamu lagi cari cara jadi agen JNE atau cara jadi agen AnterAnja? Di BukaSend kamu bisa jadi keduanya! Kamu bisa jadi agen pengiriman barang dari banyak pilihan kurir ekspedisi dengan gabung di BukaSend. Daftar agen ekspedisi yang gabung di BukaSend adalah ${COURIER_PAGES_HTML}.  Kamu bisa jadi agen dari banyak kurir ekspedisi ini secara gratis. Pengiriman paketnya juga terjamin, kurirnya tersebar di banyak lokasi di Indonesia, tarif ongkirnya murah, dan paket bisa sampai tepat waktu.`,
    },
  ],
  why: [
    {
      title: 'Apa itu Logistik dan Ekspedisi',
      content: `Secara umum ekspedisi adalah perjalanan yang dilakukan untuk tujuan tertentu secara terorganisir. Namun dalam cakupannya dengan bisnis, ekspedisi lebih diartikan sebagai proses pengiriman barang dari pihak pengirim kepada pihak penerima, dalam proses pengiriman  tersebut biasanya dilakukan oleh pihak perusahaan jasa ekspedisi.
      <br><br>
      Sementara itu Logistik cakupannya lebih besar dari itu yang mencakup pengelolaan berbagai jenis kegiatan yang berhubungan dengan kirim, pengadaan, penyediaan gudang, hingga menyimpan berbagai jenis barang dengan tujuan tertentu dan manajemen pengelolaan yang jelas. 
      <br><br>
      Jasa logistik merupakan perusahaan yang menyediakan semua kebutuhan solusi logistik mulai dari menyediakan jasa kirim barang, manajemen, transportasi hingga gudang yang bisa digunakan baik perorangan maupun untuk bisnis dari usaha kecil hingga perusahaan.
      <br><br>
      Bagi pelaku usaha, logistik merupakan salah satu hal yang berhubungan erat dengan operasional bisnis ataupun perusahaan. Melalui proses logistik yang baik, proses pemasaran, penjualan, penyediaan produk juga pengiriman produk ke customer bisa berjalan dengan lancar.`,
    },
    {
      title: 'Bukasend, Solusi Logistik Untuk Semua',
      content: `Perkenalkan Bukasend, Solusi logistik untuk semua, Diluncurkan pada tahun 2019, Bukasend awalnya ditujukan sebagai solusi pengiriman barang bagi para penjual online di berbagai platform jualan online. 
      <br><br>
      Kini bukasend hadir untuk semua dan bisa digunakan oleh siapapun baik secara perorangan yang memiliki kebutuhan kirim paket, ataupun untuk kebutuhan bisnis dengan berbagai skala usaha, mulai dari penjual online di semua marketplace, online shop di berbagai lapak sosial media atau yang disebut dengan social commerce seperti jualan di Instagram, facebook, whatsapp hingga tiktok, Umkm, pengusaha rumahan bahkan juga untuk seller offline yang memiliki kebutuhan <a href="/bukasend/kirim-barang" class="u-txt--underline u-txt--gray-60">kirim barang</a>.
      <br><br>
      Bukan itu saja, Bukasend kini menyediakan platofrm bukasend for business yang dirancang khusus dengan berbagai solusi untuk beragam segmentasi bisnis baik perusahaan kecil, menengah maupun perusahan besar untuk memenuhi kebutuhan operasional bisnis setiap perusahaan. 
      <br><br>
      Bukasend siap memberi solusi mengantar paket kamu, menyediakan beragam pilihan kurir yang dapat menjangkau seluruh wilayah di indonesia dengan tarif yang juga kompetitif. Kamu bisa memilih kurir sesuai kebutuhanmu, mulai dari pengiriman instan, sameday, regular hingga kargo. Semua bisa di Bukasend`,
    },
    {
      title: 'Fitur Unggulan Bukasend, Bikin Semua Mudah, Bikin Semua Cuan',
      content: `Karena semua butuh solusi, Bukasend hadir dengan berbagai fitur unggulan yang dibuat khusus untuk memenuhi segala kebutuhan operasional logistik para mitra bukasend. 
      <br><br>
      Untuk kebutuhan kirim barang ke custiomer, para penjual offline dan online bisa memanfaatkan solusi kirim paket dengan berbagai jenis pilihan layanan pengiriman dari yang reguler, kargo, cod, diantar ke agen sampai yang dijemput kurir langsung ke tempat kamu.
      <br><br>
      Durasi lama pengiriman bsa disesuaikan dengan kebutuhan kamu dan konsumen kamu, semuanya lengkap dari yang instan, sameday, next day ataupun yang sampai dalam beberapa hari. 
      <br><br>
      Pilihan hargapun kompetitif, kamu bisa melakukan cek ongkir dan bandingkan harga dari berbagai kurir ekspedisi rekanan bukasend. JIka barang sudah dikirim, kamu bisa cek resi dan tracking langsung paket kamu lewat aplikasi Bukasend. Eits bukan itu saja, bukasend juga punya banyak promo dan diskon pengiriman barang. mudah, murah, hemat, Bukasend memang solutif.
      <br><br>
      Selain sebagai solusi logistik, Bukasend juga bisa kasih kamu cuan dengan mendaftarkan diri jadi agen pengiriman barang di Bukasend. Caranya mudah, gak pakai lama dan yang paling penting semuanya gratis.Kamu gak perlu bayar biaya atau mengeluarkan modal apapun untuk mulai jadi <a href="/bukasend/agen-bukasend" class="u-txt--underline u-txt--gray-60">agen Bukasend</a>`,
    },
    {
      title: 'Bukasend For Business, Solusi Mudah Untuk Bisnis Kamu',
      content: `<a href="/bukasend/business" class="u-txt--underline u-txt--gray-60">Bukasend for business</a> adalah salah satu platform bukasend yang ditujukan bagi para pemilik bisnis baik untuk usaha kecil, usaha menengah ataupun perusahaan yang membutuhkan solusi logistik dan operasional untuk bisnisnya. 
      <br><br>
      Dengan fitur Integrasi API gratis, bisnis kamu hanya perlu melakukan satu kali integrasi dengan BukaSend API, maka kamu sudah bisa mendapatkan akses ke semua ekspedisi yang kamu inginkan dari Cek Ongkir, Pickup service, Cetak Resi hingga Tracking barang kiriman kamu.
      <br><br>
      Sementara itu bagi bisnis kamu yang ingin mendapatkan kemudahan pengiriman barang namun tanpa mengembangkan integrasi API, Bukasend memiliki solusi lainnya yaitu Layanan Dashboard pengiriman Bukasend yang dapat digunakan oleh kamu pemilik bisnis.
      <br><br>
      Sedangkan untuk kamu pemilik bisnis ecommerce yang memiliki toko online menggunakan layanan web builder, Kini Bukasend juga bisa diintegrasikan ke plugin e-commerce web builder ternama seperti Shopify.`,
    },
    {
      title: 'Pilihan Mitra Ekspedisi BukaSend Terlengkap',
      content: `Ada banyak pilihan kurir ekspedisi yang bergabung di BukaSend seperti ${COURIER_PAGES_HTML}. Setiap ekspedisi memiliki agen yang tersebar di seluruh pulau yang ada di Indonesia, sehingga memudahkan kamu untuk memilih ekspedisi terdekat dari lokasi kamu ataupun bisnis kamu.
      <br><br>
      Pengiriman paketnya dijamin aman, kurirnya tersedia di seluruh indonesia, paket bisa sampai dengan cepat dan pastinya tepat waktu. Untuk urusan tarif ongkir, harganya dijamin kompetitif, banyak pilihan promo dan diskon juga dari masing-masing mitra ekspedisi. Coba juga fitur cek ongkir bukasend, supaya kamu dapat membandingkan pilihan harga, durasi pengiriman dan ekspedisi yang terbaik buat kamu.
      <br><br>
      Kamu bisa cek resi dan live tracking paketnya juga langsung dari aplikasi Bukasend. Pastinya BukaSend memudahkan proses shipping barang kamu ke konsumen dan membantu memudahkan operasional logistik bisnis kamu.`,
    },
  ],
  delivery_cost_check: [
    {
      title: 'Kirim Barang Lebih Cepat dan Hemat Bersama BukaSend',
      content:
        'Pelaku UMKM, bisnis, maupun pedagang perlu melakukan cek ongkos kirim sebelum melakukan pengiriman barang guna mengetahui perkiraan biaya pengiriman, memilih layanan pengiriman sesuai kebutuhan, dan mengetahui perkiraan waktu pengiriman agar cepat sampai di tangan pembeli. Secara tidak langsung, pengecekan ongkos kirim ini akan berpengaruh pada layanan pengiriman barang yang handal bagi bisnis. Kenapa demikian? Dengan mengetahui tarif dan memiliki pengiriman barang yang baik, bisnis akan menghemat biaya dan tenaga operasional karena minim kesalahan, memberi jaminan keamanan barang sampai ke pembeli, memenangkan kompetisi di bisnis yang digeluti, dan menjaga nama baik bisnis. Oleh karena itu, BukaSend melalui fitur Cek Ongkir ingin membantu setiap bisnis di Indonesia untuk mengetahui tarif ongkos kirim pengiriman barang sehingga dapat mengoptimalkan bisnis yang sedang berjalan.',
    },
    {
      title: 'Apa Keunggulan Cek Tarif Pengiriman Barang di BukaSend?',
      content:
        'Pertama, hanya dengan beberapa klik saja fitur bisa memberi hasil dalam hitungan detik. Setiap pengguna hanya perlu masukkan kecamatan asal, kecamatan tujuan, dan berat paket, kemudian fitur akan langsung menyajikan informasi ekspedisi yang lengkap.<br>Kedua, fitur bisa memberi bisnis pilihan untuk menentukan apakah pengiriman barang akan diantar sendiri atau dijemput oleh kurir dengan jaminan paket akan dijemput tepat waktu.<br>Ketiga, BukaSend akan memberi hasil yang sangat variatif sehingga dapat disesuaikan dengan kebutuhan bisnis. Pengguna bisa memilih waktu pengiriman 2-3 hari, 1 hari, atau 2-6 jam. Dengan jasa ekspedisi yang berbeda-beda.<br>Keempat, bisnis bisa memanfaatkan promo seperti cashback yang sering ditawarkan oleh BukaSend dan promo tersebut langsung ditampilkan saat mengecek ongkos kirim.',
    },
    {
      title: 'Harga Ongkir dan Ekspedisi yang Tersedia di BukaSend',
      content:
        'Harga ongkir yang tersedia memang beragam sesuai kebutuhan. Namun, BukaSend terus berupaya agar harga ongkir yang tertera jauh lebih mudah dibanding platform lain.<br>BukaSend juga sering memberi promosi seperti pengiriman paket hanya Rp1 saja dengan maksimal promo Rp10rb. Contohnya, bisnis ingin melakukan pengiriman barang seberat 1 kg dari Bogor ke Jakarta maka hanya akan dimintai biaya sebesar Rp1 saja untuk waktu pengiriman 2-3 hari dan 1 hari. Bahkan jika ingin dalam waktu 2-6 jam, bisnis hanya perlu membayar Rp15rb.<br>Saat ini, ekspedisi dan layanan pengiriman yang sudah tersedia di BukaSend antara lain JNE, J&T, Lion Parcel, Sicepat, Wahana, Anteraja, NinjaExpress, ID Express, SAP Express, GrabExpress, dan Paxel.<br>Coba yuk, klik tautan di bawah untuk cek harga pengiriman beberapa ekspedisi BukaSend:<br>Cek Tarif <a href="/bukasend/kurir/jne" class="u-txt--underline u-fg--ash-dark">JNE</a>, Cek Tarif <a href="/bukasend/kurir/jnt-express" class="u-txt--underline u-fg--ash-dark">J&T</a>, Cek Tarif <a href="/bukasend/kurir/lion-parcel" class="u-txt--underline u-fg--ash-dark">Lion Parcel</a>, Cek Tarif <a href="/bukasend/kurir/sicepat" class="u-txt--underline u-fg--ash-dark">Sicepat</a>, Cek Tarif <a href="/bukasend/kurir/wahana" class="u-txt--underline u-fg--ash-dark">Wahana</a>, Cek Tarif <a href="/bukasend/kurir/anteraja" class="u-txt--underline u-fg--ash-dark">Anteraja</a>, Cek Tarif <a href="/bukasend/kurir/ninja-xpress" class="u-txt--underline u-fg--ash-dark">NinjaExpress</a>, Cek Tarif <a href="/bukasend/kurir/grab-xpress" class="u-txt--underline u-fg--ash-dark">GrabExpress</a>.',
    },
    {
      title: 'Permudah Pengiriman Barang Bisnis Sekarang',
      content:
        'Sebagai informasi, BukaSend merupakan fitur pengiriman barang yang bisa siapa saja akses di aplikasi Bukalapak atau Mitra Bukalapak. Semua ekspedisi, pilihan tarif, maupun waktu pengiriman kini tersedia dalam satu aplikasi terpusat. <br>BukaSend juga menjamin keamanan pembayaran dan memberi akses live tracking untuk setiap barang yang bisnis kepada pembeli. BukaSend bisa diakses melalui handphone kapan pun di mana pun.<br>Tunggu apalagi? Segera <a href="/bukasend/delivery-cost-check" class="u-txt--underline u-fg--ash-dark">Cek Ongkir</a> semua ekspedisi di BukaSend dan buktikan ongkos kirim BukaSend lebih murah dan cepat sekarang juga!  <br>Jangan lupa untuk manfaatkan fitur yang ditawarkan BukaSend yang lain seperti <a href="/bukasend/kirim-barang" class="u-txt--underline u-fg--ash-dark">Kirim Barang</a>, <a href="/bukasend/agen-bukasend" class="u-txt--underline u-fg--ash-dark">Agen BukaSend</a>, dan <a href="/bukasend/business" class="u-txt--underline u-fg--ash-dark">BukaSend untuk Bisnis</a>.',
    },
  ],
};

const BUDGET_PROMO_TYPES = {
  MEMBERSHIPS: 'partner_logistic_cashback',
  ONGKIR_GOKIL: 'cashback_ongkir_gokil',
};

const DEFAULT_COD_TRX_LIMIT = 5;

const DEFAULT_TRANSACTIONS_FILTERS = {
  states: [],
  recipients: [],
  couriers: [],
  cashbackStatuses: [],
  cod: false,
  latePickup: false,
};

const PAYMENT_METHODS = {
  mitraWallet: 'mitra_payment',
  xendit: 'xendit',
};

module.exports = {
  API_BASE_URL,
  INTERFACE_KEY,
  IS_DEV_MODE,
  WRAP_API,
  USER_ID,
  CAROUSEL_DEFAULT_OPTIONS,
  MAX_WEIGHT,
  MAX_TRANSACTION,
  MAX_ORDER,
  COURIER_SERVICE,
  COURIER_SERVICE_TYPES,
  BUKASEND_TRANSACTION_TYPE,
  PROMO_TYPES,
  PROMO_TYPES_WITH_CASHBACK,
  HIDDEN_IF_CASHBACK_ZERO_PROMO_TYPES,
  CONTACT_CHANNELS,
  DEFAULT_ERROR_MESSAGE,
  STATIC_DELIVERY_TYPES,
  STATIC_SERVICE_TYPES,
  PICKUP_SERVICES,
  DROPOFF_SERVICES,
  PAYMENT_METHOD_NAMES,
  WEIGHT_UNITS,
  BUKASEND_INSURANCE_POLICIES,
  BUKASEND_CASHBACK_LABEL_DEFAULT,
  BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT,
  BUKASEND_MISC_CONFIG_DEFAULT,
  BUKASEND_REBOOK_CONFIG_DEFAULT,
  BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT,
  MEMBERSHIP_TIERS,
  MEMBERSHIP_TIER_NAMES,
  BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT,
  CONNECTION_ERROR_TYPES,
  PROHIBITED_TNC,
  INVOICE_STATUS_LABELS,
  SYNC_ORDER_STATUS,
  TRANSACTIONS_FILTERS,
  PROMO_USER_TYPES,
  LANDING_FAQ,
  LANDING_SEO_FOOTER,
  BUDGET_PROMO_TYPES,
  DEFAULT_COD_TRX_LIMIT,
  DEFAULT_TRANSACTIONS_FILTERS,
  PAYMENT_METHODS,
};
